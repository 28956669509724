<template>
  <div class="apiTaskList">
    <div class="searchCondition">
      <div class="searchConditionItem">
        <span>名称：</span>
        <a-input
          v-model.trim="name"
          placeholder="请输入名称"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchConditionItem">
        <span>{{ active == "2" ? "Topic" : "接口编码" }}：</span>
        <a-input
          v-model.trim="apiCode"
          :placeholder="'请输入' + (active == '2' ? 'Topic' : '接口编码')"
          allowClear
          @change="allowClearChange"
          @pressEnter="query(1)"
        />
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="query(1)" icon="search">
          查询
        </a-button>
        <a-button type="primary" @click="editFormShow = true" icon="plus"
          >新增{{
            active == "1" ? "HTTP" : active == "2" ? "KAFKA" : "FTP"
          }}接口</a-button
        >
      </div>
    </div>

    <a-table
      :rowClassName="$common.rowClassColor"
      bordered
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableData"
      :pagination="tablePagination"
      size="small"
      :rowKey="(record) => record.id"
      :loading="loadingTable"
    >
      <span slot="pipelineTaskName" slot-scope="text, record">
        <a-tooltip placement="left">
          <span slot="title"> 跳转 </span>
          <a-button type="link" @click="jumpPipelineList(record)">
            {{ record.pipelineTaskName }}
          </a-button>
        </a-tooltip>
      </span>
      <span slot="enabled" slot-scope="text, record">
        <a-switch
          checked-children="已启用"
          un-checked-children="已禁用"
          v-model="record.enabled"
          @change="switchStatus(record)"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-button
          type="link"
          @click="subtaskList(record)"
          :disabled="type == 'KAFKA'"
          >子任务列表</a-button
        >
        <a-divider type="vertical" />
        <a-button type="link" @click="bind_user(record)" v-if="type == 'API'"
          >绑定用户</a-button
        >
        <a-divider type="vertical" v-if="type == 'API'" />
        <a-button type="link" @click="edit_api(record)">修改</a-button>
        <a-divider type="vertical" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-button
                type="link"
                size="small"
                @click="setPipelineTaskClean(record)"
                >设置清理周期</a-button
              >
            </a-menu-item>
            <a-menu-item v-if="type !== 'KAFKA'">
              <a-button type="link" size="small" @click="setConcurrent(record)"
                >设置并发数</a-button
              >
            </a-menu-item>
            <a-menu-item v-if="type == 'FTP'">
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="ftpScanAtNow(record)"
              >
                <template slot="title"
                  >确认是否立即扫描{{ record.name }}</template
                >
                <a href="javascript:;" style="color: #1890ff">立即扫描</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                placement="left"
                okText="确认"
                cancelText="取消"
                @confirm="delete_api(record)"
              >
                <template slot="title">确认是否删除{{ record.name }}</template>
                <a href="javascript:;" style="color: #ff4d4f">删除</a>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <a-modal
      :maskClosable="false"
      :title="title"
      v-model="editFormShow"
      width="500px"
      :afterClose="editFormClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="接口名称:">
          <a-input v-model="nameAdd" placeholder="请输入接口名称" allowClear />
        </a-form-item>
        <a-form-item :label="active == '2' ? 'Topic:' : '接口编码:'">
          <a-input
            v-model="apiCodeAdd"
            :placeholder="'请输入' + (active == '2' ? 'Topic' : '接口编码')"
            allowClear
            :disabled="title == '修改'"
          />
        </a-form-item>
        <a-form-item label="任务:">
          <a-select
            v-model="pipelineTaskId"
            placeholder="请选择任务"
            showSearch
            :filterOption="false"
            @search="getPipelineList"
          >
            <a-select-option
              v-for="item in pipelineList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="FTP账号:" v-if="active == '3'">
          <a-select
            v-model="ftpId"
            placeholder="请选择FTP账号"
            showSearch
            :filterOption="false"
            @search="searchFtpServerList"
            @change="ftpServerChange"
          >
            <a-select-option
              v-for="item in ftpServerList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="active == '3'">
          <span slot="label">
            文件名称
            <a-tooltip placement="left">
              <div slot="title">支持正则表达式,例如:[\w_@]+.csv</div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px"
              />
            </a-tooltip>
          </span>
          <a-input v-model="fileName" placeholder="请输入文件名称" />
        </a-form-item>
        <a-form-item v-if="active == '3'">
          <span slot="label">
            扫描路径
            <a-tooltip placement="left">
              <div slot="title">支持日期表达式,例如/${yyyyMMdd}</div>
              <a-icon
                type="question-circle"
                style="color: #1879ff; font-size: 14px"
              />
            </a-tooltip>
          </span>
          <a-input v-model="path" placeholder="请输入路径" />
        </a-form-item>
        <a-form-item label="启用状态:" v-if="title == '新增'">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabled"
          />
        </a-form-item>
        <a-form-item label="被动模式:" v-if="active == '3' && ftpType == 'ftp'">
          <a-checkbox v-model="enterLocalPassiveMode" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="editForm_submit">确定</a-button>
          <a-button type="primary" @click="fileMatchTest" v-if="active == '3'"
            >测试</a-button
          >
          <a-button @click="editFormShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      title="测试ftp文件读取"
      v-model="fileMatchTestShow"
      :maskClosable="false"
    >
      <div v-for="item in fileMatchTestList">
        {{ item }}
      </div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="fileMatchTestShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="绑定用户"
      v-model="editUserShow"
      width="59.5%"
      :afterClose="editUserClose"
      :footer="null"
    >
      <a-button type="primary" @click="addUserOption" icon="plus"
        >新增绑定用户</a-button
      >
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(formTableColumns)"
        :columns="formTableColumns"
        :dataSource="formTableData"
        :pagination="formTablePagination"
        size="small"
        style="margin-top: 10px"
        :rowKey="(record) => record.id"
        :loading="formLoadingTable"
      >
        <span slot="enabled" slot-scope="text, record">
          <a-switch
            checked-children="已启用"
            un-checked-children="已禁用"
            v-model="record.enabled"
            @change="edit_formItem(record, true)"
          />
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button type="link" @click="edit_formItem(record)">修改</a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="delete_formItem(record)"
          >
            <template slot="title">确认是否删除{{ record.apiCode }}</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>

    <a-modal
      :maskClosable="false"
      :title="titleUser"
      v-model="editUserFromShow"
      width="500px"
      :afterClose="editUserFromClose"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="接口编码:">
          <a-input
            v-model="apiCodeUserAdd"
            placeholder="请输入接口编码"
            allowClear
            disabled
          />
        </a-form-item>
        <a-form-item label="账号:">
          <a-select
            v-model="accessKey"
            placeholder="请选择账号"
            showSearch
            :filterOption="false"
            @search="getAccessKeyList"
          >
            <a-select-option
              v-for="item in accessKeyList"
              :key="item.accessKey"
              :value="item.accessKey"
            >
              {{ item.accessKey }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="启用状态:">
          <a-switch
            checked-children="启用"
            un-checked-children="禁用"
            v-model="enabledUser"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button type="primary" @click="editUser_submit">确定</a-button>
          <a-button @click="editUserFromShow = false">取消</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      :maskClosable="false"
      title="子任务列表"
      v-model="subtaskListShow"
      width="1000px"
      :afterClose="subtaskListClosed"
      :footer="null"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          <span>任务名称：</span>
          <a-input
            v-model.trim="subTaskName"
            placeholder="请输入任务名称"
            allowClear
            @change="allowClearSubChange"
            @pressEnter="subtaskList(null, 1)"
          />
        </div>
        <div class="searchConditionItem">
          <span>状态：</span>
          <a-select
            v-model="statusSelect"
            allowClear
            placeholder="请选择"
            @change="subtaskList(null, 1)"
          >
            <a-select-option
              v-for="item in statusList"
              :key="item.code"
              :value="item.code"
            >
              {{ item.desc }}
            </a-select-option>
          </a-select>
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="subtaskList(null, 1)" icon="search"
            >查询</a-button
          >
        </div>
      </div>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(subtaskListTableColumns)"
        :columns="subtaskListTableColumns"
        :dataSource="subtaskListTableData"
        :pagination="subtaskListTablePagination"
        size="small"
        :rowKey="(record) => record.id"
        :loading="subtaskListLoadingTable"
        :row-selection="{
          onChange: onSelectChange,
          selectedRowKeys: subtaskListTableSelectKeys,
        }"
      >
        <span slot="status" slot-scope="text, record">
          <a-tag :color="record.statusColor">{{ record.statusLabel }}</a-tag>
          <a-tooltip
            v-if="record.status == 'SCHEDULED'"
            :title="'执行时间:' + record.executeDate"
            placement="top"
          >
            <a-icon type="exclamation-circle" style="color: #d2983c" />
          </a-tooltip>
        </span>
        <span slot="executeSecond" slot-scope="text, record">
          <span v-if="record.status == 'SCHEDULED'">-</span>
          <span v-else>
            {{ $common.timeConsuming(record.executeSecond) }}
          </span>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button type="link" @click="cook_pipeline(record)">{{
            record.status == "DRAFT" ? "编辑" : "查看"
          }}</a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            v-if="record.status == 'WAITING'"
            okText="确认"
            cancelText="取消"
            @confirm="up_pipeline(record)"
          >
            <template slot="title">是否启动{{ record.name }}任务?</template>
            <a href="javascript:;">启动任务</a>
          </a-popconfirm>
          <a-divider type="vertical" v-if="record.status == 'WAITING'" />
          <a-popconfirm
            placement="left"
            v-if="record.status == 'RUNNING'"
            okText="确认"
            cancelText="取消"
            @confirm="stopTaskNow(record)"
          >
            <template slot="title">是否中断执行?</template>
            <a href="javascript:;">中断执行</a>
          </a-popconfirm>
          <a-divider type="vertical" v-if="record.status == 'RUNNING'" />
          <a-button
            type="link"
            @click="file_download(record)"
            :disabled="record.status !== 'SUCCESS' && record.expired !== true"
            >文件下载</a-button
          >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="deletePipelineTask(record)"
          >
            <template slot="title">确认是否删除?</template>
            <a href="javascript:;" style="color: #ff4d4f">删除</a>
          </a-popconfirm>
        </span>
      </a-table>

      <div style="margin-top: 20px">
        <a-button
          @click="batchDelete()"
          type="danger"
          :disabled="this.subtaskListTableSelectKeys.length == 0"
          icon="delete"
          >批量删除</a-button
        >
        <a-button
          @click="batchStart()"
          type="primary"
          style="margin-left: 10px"
          :disabled="batchStartDisable"
          >批量启动</a-button
        >
      </div>
    </a-modal>

    <a-modal
      :maskClosable="false"
      v-model="fileListShow"
      width="1000px"
      :afterClose="fileListClosed"
      :footer="null"
      title="下载文件列表"
    >
      <a-tabs v-model="fileActive">
        <a-tab-pane tab="重要文件" key="first"></a-tab-pane>
        <a-tab-pane tab="临时文件" key="second"></a-tab-pane>
      </a-tabs>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(fileListTableColumns)"
        :columns="fileListTableColumns"
        :dataSource="fileListTableData"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
        :loading="fileListLoadingTable"
      >
        <span slot="lineCountLabel" slot-scope="text, record">
          <a-tooltip placement="left">
            <div slot="title">
              {{ record.lineCount }}
            </div>
            <span> {{ text }} </span>
          </a-tooltip>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-button
            type="link"
            size="small"
            @click="downloadClick(record)"
            :disabled="record.expired"
            >下载</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="shareClick(record)"
            >分享</a-button
          >
          <a-divider type="vertical" />
          <a-button
            type="link"
            size="small"
            @click="checkFileContent(record)"
            :disabled="
              record.fileName.split('.')[
                record.fileName.split('.').length - 1
              ] == 'zip'
            "
            >查看</a-button
          >
          <a-divider type="vertical" />
          <a-button type="link" size="small" @click="copyPath(record)"
            >复制路径</a-button
          >
        </span>
      </a-table>
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="30%"
      title="分享设置"
      v-model="innerVisible"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="密码">
          <a-switch
            v-model="passwordSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="password == ''"
          />
          <template v-if="passwordSwitch">
            <a-input
              v-model="password"
              placeholder="请输入8位以上密码"
              style="width: 250px; margin: 0 5px"
            />
            <a-button @click="random" type="link">随机</a-button>
          </template>
        </a-form-item>
        <a-form-item label="过期时间">
          <a-select v-model="expireSeconds" placeholder="请选择过期时间">
            <a-select-option
              v-for="item in expireSecondList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="文件空间">
          <a-select v-model="fileSpaceId" placeholder="请选择">
            <a-select-option
              v-for="item in fileSpaceList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea v-model="remark" placeholder="请输入备注" :rows="4" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="innerVisible = false">取 消</a-button>
          <a-button type="primary" @click="shareConfirm">确 定</a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="30%"
      title="复制链接"
      v-model="copyVisible"
      :footer="false"
      :maskClosable="false"
    >
      <a-textarea v-model="inviteCode" :rows="2" />
      <a-button
        type="primary"
        class="copy-code-button"
        v-copy="inviteCode"
        style="margin-top: 10px"
        >一键复制</a-button
      >
    </a-modal>

    <a-modal
      :maskClosable="false"
      width="700px"
      title="查看文件"
      v-model="checkFileContentShow"
      :footer="null"
    >
      <a-table
        v-if="fileType == 'xls' || fileType == 'xlsx'"
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(excelFileColumns)"
        :columns="excelFileColumns"
        :dataSource="excelFileList"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.id"
      />

      <a-textarea
        v-else
        v-model="fileContent"
        :rows="20"
        style="margin-bottom: 10px"
      />
      <h4
        style="margin: 0 0 10px 0"
        v-if="
          fileContent.split('\n').length == 500 || excelFileList.length == 500
        "
      >
        最多只能显示五百行
      </h4>
    </a-modal>

    <a-modal
      width="500px"
      title="设置清理周期"
      v-model="setPipelineTaskCleanShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="清理周期:">
          <a-select v-model="day" placeholder="请选择清理周期" allowClear>
            <a-select-option
              v-for="(item, index) in dayList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="setPipelineTaskCleanShow = false">取 消</a-button>
          <a-button type="primary" @click="setPipelineTaskCleanSubmit"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      width="500px"
      title="设置并发数"
      v-model="setConcurrentShow"
      :maskClosable="false"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="并发数:">
          <a-switch
            checked-children="限制"
            un-checked-children="不限制"
            v-model="maxConcurrentShow"
          />
          <a-input
            v-if="maxConcurrentShow"
            v-model.trim="maxConcurrent"
            placeholder="请输入并发数"
            allowClear
            style="width: 200px; margin-left: 10px"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="setConcurrentShow = false">取 消</a-button>
          <a-button type="primary" @click="setConcurrentSubmit">确 定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: ["active"],
  name: "apiTaskList",
  data() {
    return {
      tableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "接口编码",
          ellipsis: true,
          dataIndex: "apiCode",
          width: 200,
        },
        {
          title: "关联任务",
          ellipsis: true,
          dataIndex: "pipelineTaskName",
          scopedSlots: { customRender: "pipelineTaskName" },
          width: 200,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          align: "center",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 250,
        },
      ],
      tableData: [],
      loadingTable: false,
      tablePagination: {},
      currentPage: 1,
      form_currentPage: 1,
      name: "",
      apiCode: "",
      editFormShow: false,
      nameAdd: "",
      apiCodeAdd: "",
      pipelineTaskId: undefined,
      enabled: true,
      title: "新增",
      id: "",
      pipelineList: [],
      editUserShow: false,
      formTableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "接口编码",
          ellipsis: true,
          dataIndex: "apiCode",
          width: 260,
        },
        {
          title: "账号",
          ellipsis: true,
          dataIndex: "accessKey",
          width: 260,
        },
        {
          title: "启用状态",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          align: "center",
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ],
      formTableData: [],
      formLoadingTable: false,
      formTablePagination: {},
      titleUser: "新增",
      editUserFromShow: false,
      apiCodeUserAdd: "",
      enabledUser: true,
      accessKeyList: [],
      accessKey: undefined,
      userId: "",
      apiCodeUser: "",
      type: "API",
      ftpTypeList: [
        {
          value: "ftp",
          label: "ftp",
        },
        {
          value: "sftp",
          label: "sftp",
        },
      ],
      ftpServerList: [],
      searchTimer: null,
      path: "/",
      ftpId: undefined,
      fileName: "",
      enterLocalPassiveMode: false,
      ftpType: "ftp",
      subtaskCurrentPage: 1,
      subtaskListTableData: [],
      subtaskListTableSelectKeys: [],
      subtaskListTableSelectKeysData: [],
      subtaskListLoadingTable: false,
      subtaskListTableColumns: [
        {
          title: "序号",
          ellipsis: true,
          dataIndex: "id",
          align: "center",
          width: 100,
        },
        {
          title: "账号",
          ellipsis: true,
          dataIndex: "userLoginName",
          width: 150,
        },
        {
          title: "任务名称",
          ellipsis: true,
          dataIndex: "name",
          width: 150,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
          width: 100,
        },
        {
          title: "耗时",
          ellipsis: true,
          dataIndex: "executeSecond",
          scopedSlots: { customRender: "executeSecond" },
          align: "center",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 230,
        },
      ],
      subtaskListTablePagination: {},
      subtaskListShow: false,
      fileListTableData: [],
      fileListLoadingTable: false,
      fileListTableColumns: [
        {
          title: "编号",
          ellipsis: true,
          dataIndex: "id",
          width: 90,
          align: "center",
        },
        {
          title: "文件名称",
          ellipsis: true,
          dataIndex: "fileName",
          width: 180,
        },
        {
          title: "文件大小",
          ellipsis: true,
          dataIndex: "fileSize",
          width: 100,
          align: "center",
        },
        {
          title: "行数",
          ellipsis: true,
          dataIndex: "lineCountLabel",
          scopedSlots: { customRender: "lineCountLabel" },
          width: 80,
          align: "center",
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "label",
          width: 80,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          align: "center",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 220,
        },
      ],
      fileListShow: false,
      innerVisible: false,
      password: "",
      expireSeconds: "",
      remark: "",
      passwordSwitch: false,
      expireSecondList: [
        { id: 1, label: "永不过期", value: -1 },
        { id: 2, label: "1天", value: 86400 },
        { id: 3, label: "2天", value: 172800 },
        { id: 4, label: "3天", value: 259200 },
        { id: 5, label: "7天", value: 604800 },
        { id: 6, label: "15天", value: 1296000 },
        { id: 7, label: "一个月", value: 2592000 },
        { id: 8, label: "31天", value: 2592000 },
        { id: 9, label: "45天", value: 3888000 },
        { id: 10, label: "6个月", value: 15552000 },
        { id: 11, label: "1年", value: 31536000 },
      ],
      fileSpaceId: -1,
      fileSpaceList: [],
      parentTaskId: 0,
      subTaskName: "",
      statusSelect: undefined,
      statusList: [],
      pipelineLogId: "",
      fileActive: "first",
      copyVisible: false,
      inviteCode: "",
      fileType: "txt",
      checkFileContentShow: false,
      fileContent: "",
      excelFileList: [],
      excelFileColumns: [],
      shareTimer: null,
      syncLoading: null,
      taskId: undefined,
      day: undefined,
      setPipelineTaskCleanShow: false,
      dayList: [
        { label: "一天", value: 1 },
        { label: "两天", value: 2 },
        { label: "三天", value: 3 },
        { label: "七天", value: 7 },
        { label: "十五天", value: 15 },
      ],
      fileMatchTestList: [],
      fileMatchTestShow: false,
      maxConcurrent: "",
      maxConcurrentShow: false,
      setConcurrentShow: false,
    };
  },
  watch: {
    fileActive: {
      handler(newVal, oldVal) {
        switch (newVal) {
          case "first":
            this.getFileListTableData(this.pipelineLogId, true);
            break;
          case "second":
            this.getFileListTableData(this.pipelineLogId, false);
            break;
        }
      },
    },
    active: {
      immediate: true,
      handler(newVal) {
        this.tabClick(newVal);
      },
    },
  },
  computed: {
    batchStartDisable() {
      let arr = this.subtaskListTableSelectKeysData.filter((item) => {
        return item.status == "WAITING";
      });
      if (arr.length > 0) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.getPipelineList();
    this.getAccessKeyList();
    this.getStatus();
  },
  methods: {
    tabClick(key) {
      switch (key) {
        case "1":
          this.type = "API";
          this.tableColumns[2].title = "接口编码";
          break;
        case "2":
          this.type = "KAFKA";
          this.tableColumns[2].title = "Topic";
          break;
        case "3":
          this.type = "FTP";
          this.tableColumns[2].title = "接口编码";
          this.getFtpServerList();
          break;
      }
      localStorage.setItem("apiTaskListActive", key);
      this.query(1);
    },
    searchFtpServerList(name) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
        this.searchTimer = null;
      }
      this.searchTimer = setTimeout(() => {
        this.getFtpServerList(name);
      }, 300);
    },
    ftpServerChange() {
      let ftpServer = this.ftpServerList.filter((item) => {
        return item.id == this.ftpId;
      })[0];
      this.ftpType = ftpServer.type;
    },
    getFtpServerList(name) {
      let data = {
        name,
      };
      this.$http.get("/ftp/ftpServerList", data).then((res) => {
        if (res.result === 200) {
          this.ftpServerList.splice(0);
          this.ftpServerList.push(...res.data);
        }
      });
    },
    jumpPipelineList(val) {
      this.$router.push({
        path: "/panel",
        query: { id: val.pipelineTaskId },
      });
    },
    getAccessKeyList(val) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        accessKey: val,
      };
      this.$http
        .get("/pipelineApiUser/pipelineApiUserPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.accessKeyList.splice(0);
            this.accessKeyList.push(...res.data.records);
          }
        });
    },
    editUser_submit() {
      let data = {
        accessKey: this.accessKey,
        apiCode: this.apiCodeUserAdd,
        enabled: this.enabledUser,
      };
      if (this.titleUser == "新增") {
        this.$http
          .json_post(
            "/pipelineApiUserRelation/addPipelineApiUserRelation",
            data
          )
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("新增成功");
              this.editUserFromShow = false;
              this.bind_user();
            }
          });
      } else {
        data.id = this.userId;
        this.$http
          .json_post(
            "/pipelineApiUserRelation/editPipelineApiUserRelation",
            data
          )
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.editUserFromShow = false;
              this.bind_user();
            }
          });
      }
    },
    editUserFromClose() {
      this.titleUser = "新增";
      this.apiCodeUserAdd = this.apiCodeAdd;
      this.accessKey = undefined;
      this.enabledUser = true;
      this.userId = "";
    },
    edit_formItem(val, enable) {
      this.titleUser = "修改";
      this.userId = val.id;
      this.apiCodeUserAdd = val.apiCode;
      this.accessKey = val.accessKey;
      this.enabledUser = val.enabled;
      if (enable) {
        this.editUser_submit();
      } else {
        this.editUserFromShow = true;
      }
    },
    delete_formItem(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post(
          "/pipelineApiUserRelation/deletePipelineApiUserRelation",
          data
        )
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.bind_user();
          }
        });
    },
    editUserClose() {
      this.nameAdd = "";
      this.apiCodeAdd = "";
      this.enabled = true;
      this.id = "";
      this.form_currentPage = 1;
    },
    addUserOption() {
      this.titleUser = "新增";
      this.apiCodeUserAdd = this.apiCodeAdd;
      this.editUserFromShow = true;
    },
    // 密码随机
    random() {
      this.password = this.$common.random();
    },
    // 分享确定
    shareConfirm() {
      this.syncLoading = this.$syncLoading({
        message: "分享链接生成中...",
      });
      this.getFileShareLink();
    },
    getFileShareLink() {
      let data = {
        id: this.jobId,
        fileId: this.fileId,
        password: this.password,
        expireSeconds: this.expireSeconds,
        fileSpaceId: this.fileSpaceId,
        remark: this.remark,
      };
      this.innerVisible = false;
      this.$http
        .get("/pipelineTask/getFileShareLink", data)
        .then((res) => {
          if (res.result === 200) {
            if (res.data.url) {
              this.copyVisible = true;
              this.inviteCode = res.data.url;
              this.syncLoading.close();
              this.$message.success("分享成功");
              clearInterval(this.shareTimer);
              this.shareTimer = null;
            } else if (!this.shareTimer) {
              this.shareTimer = setInterval(() => {
                this.getFileShareLink();
              }, 2000);
            }
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    checkFileContent(val) {
      this.fileContent = "";
      this.excelFileList.splice(0);
      this.excelFileColumns.splice(0);
      this.fileType = val.fileName.split(".")[1];
      this.syncLoading = this.$syncLoading({
        message: "获取文件内容中...",
      });
      this.$http
        .get("/pipelineFile/checkFileContent", { fileId: val.id })
        .then((res) => {
          if (res.result == 200) {
            if (this.fileType == "xlsx" || this.fileType == "xls") {
              res.data.forEach((item, index) => {
                let obj = {
                  id: index + 1,
                };
                item.forEach((value, key) => {
                  obj["column" + (key + 1)] = value;
                });
                this.excelFileList.push(obj);
              });

              res.data[0].forEach((value, index) => {
                this.excelFileColumns.push({
                  title: "列" + (index + 1),
                  ellipsis: true,
                  dataIndex: "column" + (index + 1),
                  width: 100,
                });
              });
            } else {
              this.fileContent = res.data[0].join("\n");
            }
            this.syncLoading.close();
            this.checkFileContentShow = true;
          }
        })
        .catch(() => {
          this.syncLoading.close();
        });
    },
    // 点击分享
    shareClick(val) {
      this.fileId = val.id;
      this.shareUrl = val.link;
      this.innerVisible = true;
      this.jobId = val.pipelineTaskId;
      this.passwordSwitch = false;
      this.password = "";
      this.remark = "";
      this.expireSeconds = 2592000;
      this.fileSpaceId = -1;
    },
    copyPath(val) {
      let data = {
        id: val.id,
      };
      this.$http.get("/pipelineFile/copyFilePath", data).then((res) => {
        if (res.result === 200) {
          this.$copyText(res.data).then(
            (e) => {
              this.$message.success("复制成功");
            },
            (e) => {
              this.$message.error("复制失败");
            }
          );
        }
      });
    },
    // 关闭下载文件
    fileListClosed() {
      this.fileListTableData = [];
      this.fileActive = "first";
    },
    // 点击下载
    downloadClick(val) {
      let path = location.protocol + process.env.VUE_APP_BASE_API;
      let href = path + "/pipelineFile/download?id=" + val.id;
      window.location.href = href;
    },
    // 查看
    cook_pipeline(val) {
      this.$router.push({
        path: "/panel",
        query: {
          id: val.id,
        },
      });
    },
    getSpaceList() {
      this.$http.get("/job/getSpaceList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          this.fileSpaceList = list;
        }
      });
    },
    // 启动任务
    up_pipeline(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/start", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("启动成功");
          this.subtaskList(null, 1);
        }
      });
    },
    // 中断执行
    stopTaskNow(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/interruptTask", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("操作成功");
          this.subtaskList(null, 1);
        }
      });
    },
    // 点击文件下载
    file_download(val) {
      this.fileListShow = true;
      this.pipelineLogId = val.id;
      this.getSpaceList();
      this.getFileListTableData(val.id, true);
    },
    getFileListTableData(pipelineLogId, importantFile) {
      this.fileListLoadingTable = true;
      let data = {
        pipelineLogId,
        importantFile,
      };
      this.$http.get("/pipelineFile/fileList", data).then((res) => {
        if (res.result === 200) {
          this.fileListTableData = res.data;
          this.fileListTableData.map((item) => {
            if (item.lineCount == -1) {
              this.$set(item, "lineCount", "未知");
              this.$set(item, "lineCountLabel", "未知");
            } else {
              this.$set(
                item,
                "lineCountLabel",
                this.$common.formatTotalCount(item.lineCount)
              );
            }
          });
          this.fileListLoadingTable = false;
        }
      });
    },
    allowClearSubChange(e) {
      if (e.target.value) {
        return;
      }
      this.subtaskList(null, 1);
    },
    getStatus() {
      this.$http.get("/sysPipeline/statusList").then((res) => {
        if (res.result === 200) {
          this.statusList = res.data;
        }
      });
    },
    // 子任务列表
    subtaskList(val, index) {
      this.subtaskListLoadingTable = true;
      if (index) {
        this.subtaskCurrentPage = index;
      }
      if (val) {
        this.parentTaskId = val.pipelineTaskId;
        this.subTaskName = "";
        this.statusSelect = undefined;
      }
      let data = {
        pageNo: this.subtaskCurrentPage,
        pageSize: 10,
        parentTaskId: this.parentTaskId,
        name: this.subTaskName,
        status: this.statusSelect,
        pipelineTaskType: "API_TASK",
      };
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let ids = [];
          list.forEach((item) => {
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
            if (item.status === "RUNNING" || item.status === "WAITING") {
              ids.push(item.id);
            }
            switch (item.status) {
              case "QUEUEING":
                item.statusColor = "orange";
                item.statusLabel = "排队中";
                break;
              case "RUNNING":
                item.statusColor = "orange";
                item.statusLabel = "运行中";
                break;
              case "SUCCESS":
                item.statusColor = "green";
                item.statusLabel = "执行成功";
                break;
              case "ERROR":
                item.statusColor = "red";
                item.statusLabel = "执行失败";
                break;
              case "WAITING":
                item.statusColor = "orange";
                item.statusLabel = "等待执行";
                break;
              case "CANCELED":
                item.statusColor = "orange";
                item.statusLabel = "已取消";
                break;
              case "WAITING_AUDIT":
                item.statusColor = "orange";
                item.statusLabel = "等待审核";
                break;
              case "AUDIT_FAILURE":
                item.statusColor = "red";
                item.statusLabel = "审核失败";
                break;
              case "PART_READY":
                item.statusColor = "orange";
                item.statusLabel = "部分就绪";
                break;
              case "SCHEDULED":
                item.statusColor = "orange";
                item.statusLabel = "已定时";
                break;
              case "DRAFT":
                item.statusColor = "orange";
                item.statusLabel = "草稿";
                break;
            }
          });
          this.subtaskListTableData = list;
          this.subtaskListTablePagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: data.pageSize,
            current: data.pageNo,
            total: res.data.total,
            onChange: (current) => this.subtaskCurrentPage_data(current),
          };
          this.subtaskListLoadingTable = false;
          this.subtaskListShow = true;
        }
      });
    },
    subtaskCurrentPage_data(index) {
      this.subtaskList(null, index);
    },
    subtaskListClosed() {
      this.subtaskListTableData = [];
      this.subtaskCurrentPage = 1;
    },
    deletePipelineTask(val) {
      let data = {
        id: val.id,
      };
      this.$http.json_post("/pipelineTask/delete", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("删除成功");
          this.subtaskList();
        }
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.subtaskListTableSelectKeys.splice(0);
      this.subtaskListTableSelectKeys.push(...selectedRowKeys);
      this.subtaskListTableSelectKeysData.splice(0);
      this.subtaskListTableSelectKeysData.push(...selectedRows);
    },
    // 批量删除
    batchDelete() {
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">
            删除{this.subtaskListTableSelectKeys.length}个任务, 是否继续?
          </div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          if (this.subtaskListTableSelectKeys.length > 0) {
            var idList = this.subtaskListTableSelectKeys;
            let data = {
              idList,
            };
            this.$http
              .json_post("/pipelineTask/batchDelete", data)
              .then((res) => {
                if (res.result === 200) {
                  this.$message.success({
                    type: "success",
                    message: "批量删除成功!",
                  });
                  this.subtaskList(null, 1);
                } else {
                  this.$message.success({
                    type: "warning",
                    message: "失败!",
                  });
                }
                this.subtaskListTableSelectKeys.splice(0);
                this.subtaskListTableSelectKeysData.splice(0);
              });
          }
        },
        onCancel: () => {},
      });
    },
    batchStart() {
      let ids = [];
      this.subtaskListTableSelectKeysData.forEach((item) => {
        if (item.status == "WAITING" && item.groupId !== 0) {
          ids.push(item.id);
        }
      });
      this.$confirm({
        title: "提示",
        content: (h) => (
          <div style="color:red;">批量启动{ids.length}个任务, 是否继续?</div>
        ),
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          this.$axios
            .all(
              ids.map((id) => {
                let data = { id };
                return this.$http.json_post("/pipelineTask/start", data);
              })
            )
            .then((resArr) => {
              this.$message.success("启动成功");
              this.subtaskListTableSelectKeys.splice(0);
              this.subtaskListTableSelectKeysData.splice(0);
              this.subtaskList();
            });
        },
        onCancel: () => {},
      });
    },
    bind_user(val) {
      this.formLoadingTable = true;
      if (val) {
        this.apiCodeAdd = val.apiCode;
        this.enabled = val.enabled;
        this.id = val.id;
      }
      let data = {
        pageNo: this.form_currentPage,
        pageSize: 10,
        id: this.id,
        apiCode: this.apiCodeAdd,
      };
      this.$http
        .get("/pipelineApiUserRelation/pipelineApiUserRelationPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.formTableData = res.data.records;
            this.formTablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.form_currentPage_data(current),
            };
            if (!this.editUserShow) {
              this.editUserShow = true;
            }
            this.formLoadingTable = false;
          }
        });
    },
    getPipelineList(val) {
      let data = {
        pageNo: 1,
        pageSize: 10,
        name: val,
        parentTaskId: 0,
        pipelineTaskType: "API_TASK_TEMPLATE",
      };
      this.$http.get("/pipelineTask/list", data).then((res) => {
        if (res.result === 200) {
          this.pipelineList.splice(0);
          this.pipelineList.push(...res.data.records);
        }
      });
    },
    editForm_submit() {
      let data = {
        pipelineTaskId: this.pipelineTaskId,
        name: this.nameAdd,
        apiCode: this.apiCodeAdd,
        enabled: this.enabled,
      };
      if (this.active == "3") {
        let extraInfo = {
          path: this.path,
          ftpId: this.ftpId,
          fileName: this.fileName,
          type: this.ftpType,
        };
        if (extraInfo.type == "ftp") {
          extraInfo.enterLocalPassiveMode = this.enterLocalPassiveMode;
        }
        data.extraInfo = JSON.stringify(extraInfo);
      }

      if (this.title == "新增") {
        data.type = this.type;
        this.$http
          .json_post("/pipelineApiInterface/addPipelineApiInterface", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("新增成功");
              this.editFormShow = false;
              this.query();
            }
          });
      } else {
        data.id = this.id;
        this.$http
          .json_post("/pipelineApiInterface/editPipelineApiInterface", data)
          .then((res) => {
            if (res.result == 200) {
              this.$message.success("修改成功");
              this.editFormShow = false;
              this.query();
            }
          });
      }
    },
    fileMatchTest() {
      let data = {
        ftpId: this.ftpId,
        path: this.path,
        fileName: this.fileName,
      };
      if (this.ftpType == "ftp") {
        data.enterLocalPassiveMode = this.enterLocalPassiveMode;
      }
      for (const key in data) {
        if (!data[key] && key !== "enterLocalPassiveMode") {
          this.$message.warning("请填写参数后测试");
          return;
        }
      }
      this.$http.json_post("/ftp/fileMatchTest", data).then((res) => {
        if (res.result == 200) {
          this.fileMatchTestShow = true;
          this.fileMatchTestList = res.data;
          if (res.data.length == 0) {
            this.fileMatchTestList.push("没有匹配到任何文件");
          }
        }
      });
    },
    editFormClose() {
      this.title = "新增";
      this.pipelineTaskId = undefined;
      this.nameAdd = "";
      this.apiCodeAdd = "";
      this.path = "/";
      this.ftpType = "ftp";
      this.ftpId = undefined;
      this.enterLocalPassiveMode = false;
      this.fileName = "";
      this.enabled = true;
    },
    allowClearChange(e) {
      if (e.target.value) {
        return;
      }
      this.query(1);
    },
    // 查询
    query(index) {
      this.loadingTable = true;
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: 10,
        name: this.name,
        apiCode: this.apiCode,
        type: this.type,
      };
      this.getPipelineLogList(data);
    },
    // 查询记录数据
    getPipelineLogList(data) {
      this.$http
        .get("/pipelineApiInterface/pipelineApiInterfacePage", data)
        .then((res) => {
          if (res.result === 200) {
            this.tableData = res.data.records;
            this.tablePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: data.pageSize,
              current: data.pageNo,
              total: res.data.total,
              onChange: (current) => this.currentPage_data(current),
            };
            this.loadingTable = false;
          }
        });
    },
    edit_api(val) {
      this.title = "修改";
      this.pipelineTaskId = val.pipelineTaskId;
      this.nameAdd = val.name;
      this.apiCodeAdd = val.apiCode;
      this.enabled = val.enabled;
      this.id = val.id;
      if (this.active == "3") {
        let extraInfo = val.extraInfo;
        if (extraInfo) {
          extraInfo = JSON.parse(extraInfo);
          this.path = extraInfo.path;
          this.ftpType = extraInfo.type;
          if (extraInfo.enterLocalPassiveMode) {
            this.enterLocalPassiveMode = extraInfo.enterLocalPassiveMode;
          } else {
            this.enterLocalPassiveMode = false;
          }
          this.ftpId = extraInfo.ftpId;
          this.fileName = extraInfo.fileName;
        }
      }
      this.editFormShow = true;
    },
    setPipelineTaskClean(val) {
      this.taskId = val.pipelineTaskId;
      let data = {
        taskId: this.taskId,
      };
      this.$http.get("/pipelineTask/pipelineTaskClean", data).then((res) => {
        if (res.result === 200) {
          if (res.data) {
            this.day = res.data.day;
            this.id = res.data.id;
          } else {
            this.day = undefined;
            this.id = undefined;
          }
          this.setPipelineTaskCleanShow = true;
        }
      });
    },
    setPipelineTaskCleanSubmit() {
      let data = {
        taskId: this.taskId,
        day: this.day,
        id: this.id,
      };
      this.$http
        .json_post("/pipelineTask/modifyPipelineTaskClean", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.setPipelineTaskCleanShow = false;
          }
        });
    },
    setConcurrent(val) {
      this.id = val.id;
      this.maxConcurrentShow = val.maxConcurrent !== -1;
      this.maxConcurrent = val.maxConcurrent;
      this.setConcurrentShow = true;
    },
    setConcurrentSubmit() {
      let data = {
        id: this.id,
        maxConcurrent: this.maxConcurrent,
      };
      if (!this.maxConcurrentShow) {
        data.maxConcurrent = -1;
      }
      this.$http
        .json_post("/pipelineApiInterface/updateConcurrent", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.setConcurrentShow = false;
            this.query();
          }
        });
    },
    ftpScanAtNow(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineApiInterface/ftpScanAtNow", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("扫描成功");
            this.query();
          }
        });
    },
    delete_api(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/pipelineApiInterface/deletePipelineApiInterface", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.query();
          }
        });
    },
    switchStatus(val) {
      let data = {
        id: val.id,
        enabled: val.enabled,
      };
      this.$http
        .json_post("/pipelineApiInterface/switchStatus", data)
        .then((res) => {
          if (res.result === 200) {
            if (val.enabled) {
              this.$message.success("启用成功");
            } else {
              this.$message.success("禁用成功");
            }
            this.query();
          }
        });
    },
    // 翻页
    currentPage_data(index) {
      this.query(index);
    },
    form_currentPage_data() {
      this.bind_user();
    },
  },
};
</script>
