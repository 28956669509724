<template>
  <div
    ref="node"
    :style="nodeContainerStyle"
    @click.stop="clickNode"
    @contextmenu="nodeRightMenu"
    @mouseup="changeNodeSite"
    :class="nodeContainerClass"
    @dblclick="dblclickNode"
    @mouseenter="getColorShow = true"
    @mouseleave="getColorShow = false"
  >
    <!-- 最左侧的那条竖线 -->
    <div
      :class="nodeLeftStyle"
      :style="{ backgroundColor: node.type == 'COMPOSE_NODE' ? '#fff' : '' }"
      v-if="node.type !== 'PROCESS_INPUT' && node.type !== 'PROCESS_OUTPUT'"
    ></div>
    <div class="ef-node-content">
      <div
        class="ef-node-content-item"
        v-if="node.type !== 'PROCESS_INPUT' && node.type !== 'PROCESS_OUTPUT'"
      >
        <!-- 节点类型的图标 -->
        <div class="ef-node-left-ico">
          <i
            :class="nodeIcoClass"
            :style="{
              backgroundImage: `url(${node.icon})`,
              display: 'inline-block',
              width: '16px',
              height: '16px',
            }"
          ></i>
        </div>
        <!-- 节点名称 -->
        <div class="ef-node-text" :show-overflow-tooltip="true">
          <a-tooltip placement="left">
            <div slot="title">{{ node.name }}</div>
            {{ node.name }}
          </a-tooltip>
        </div>
        <div class="ef-node-content-head">
          <a-tooltip placement="left">
            <div slot="title">设置变量参数</div>
            <div
              class="ef-node-setting-ico"
              v-if="
                node.type !== 'COMPOSE_NODE' &&
                node.type !== 'PROCESS_INPUT' &&
                node.type !== 'PROCESS_OUTPUT' &&
                !processIf &&
                model == 'BATCH'
              "
              @click.stop="generateParameter()"
            >
              <img
                src="../../assets/icon/bianliang.png"
                style="width: 14px; height: 14px; margin-bottom: 2px"
              />
            </div>
          </a-tooltip>
          <a-tooltip placement="left">
            <div slot="title">参数设置</div>
            <div
              class="ef-node-setting-ico"
              v-if="node.extraInfoShow && !viewOnly && node.state == 'WAITING'"
              @click.stop="updateNode()"
            >
              <a-icon type="edit" style="color: #1879ff" />
            </div>
          </a-tooltip>
          <a-tooltip placement="left">
            <div slot="title">删除节点</div>
            <div
              class="ef-node-setting-ico"
              @click.stop="deleteNode()"
              @drag.stop
              @dragstart.stop
              @dragend.stop
              @mouseup.stop
              @mousedown.stop
              v-if="
                node.type !== 'COMPOSE_NODE' &&
                !viewOnly &&
                node.state == 'WAITING'
              "
            >
              <a-icon type="delete" style="color: red" />
            </div>
          </a-tooltip>
        </div>
        <!-- 节点状态图标 -->
        <div class="ef-node-right-ico" v-if="node.state != 'WAITING'">
          <a-tooltip placement="left">
            <div slot="title">下载文件</div>
            <a-button
              type="link"
              class="ef-node-action-ico"
              @click.stop="download()"
              style="margin-left: 5px"
            >
              <a-icon type="download" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="left">
            <div slot="title">继续执行</div>
            <a-button
              type="link"
              class="ef-node-action-ico"
              @click.stop="confirmPauseNode(true)"
              v-if="node.type == 'pauseNotifyService' && node.state == 'PAUSE'"
              style="color: #67c23a"
            >
              <a-icon type="play-circle" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="left">
            <div slot="title">停止</div>
            <a-button
              type="link"
              class="ef-node-action-ico"
              @click.stop="confirmPauseNode(false)"
              v-if="node.type == 'pauseNotifyService' && node.state == 'PAUSE'"
              style="margin-right: 5px"
            >
              <img
                src="../../assets/icon/pause.png"
                style="width: 13px; height: 13px; margin-bottom: 2px"
              />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="left" v-if="node.errorMessage">
            <div slot="title">{{ node.errorMessage }}</div>
            <a-icon
              class="ef-node-state-success"
              type="check-circle"
              v-if="node.state == 'SUCCESS'"
            />
            <a-icon
              class="ef-node-state-error"
              type="close-circle"
              v-if="node.state == 'ERROR'"
            />
            <a-icon
              class="ef-node-state-warning"
              type="exclamation-circle"
              v-if="node.state == 'CANCELED' || node.state == 'WARNING'"
            />
            <a-icon
              class="ef-node-state-warning"
              type="play-circle"
              v-if="node.state == 'WAITING'"
            />
            <a-icon
              class="ef-node-state-running"
              type="loading"
              v-if="node.state == 'RUNNING'"
            />
          </a-tooltip>
          <template v-if="!node.errorMessage">
            <a-icon
              class="ef-node-state-success"
              type="check-circle"
              v-if="node.state == 'SUCCESS'"
            />
            <a-icon
              class="ef-node-state-error"
              type="close-circle"
              v-if="node.state == 'ERROR'"
            />
            <a-icon
              class="ef-node-state-warning"
              type="exclamation-circle"
              v-if="node.state == 'CANCELED' || node.state == 'WARNING'"
            />
            <a-icon
              class="ef-node-state-warning"
              type="play-circle"
              v-if="node.state == 'WAITING'"
            />
            <a-icon
              class="ef-node-state-running"
              type="loading"
              v-if="node.state == 'RUNNING'"
            />
          </template>
        </div>
      </div>
      <div class="ef-node-content-item" v-else>
        <div class="ef-node-text" :show-overflow-tooltip="true">
          <a-tooltip placement="left">
            <div slot="title">{{ node.name }}</div>
            {{ node.name }}
          </a-tooltip>
        </div>
      </div>
      <!-- 附加参数 -->

      <a-tooltip placement="bottom">
        <span slot="title" v-if="node.remark">
          {{ node.remark }}
        </span>

        <div
          class="ef-node-content-extraInfo"
          v-if="node.type !== 'PROCESS_INPUT' && node.type !== 'PROCESS_OUTPUT'"
        >
          <template v-if="node.type !== 'COMPOSE_NODE'">
            <div
              v-if="node.property.keepResultFile"
              class="ef-node-content-info"
            >
              保留文件：
              <a-tooltip placement="left">
                <div slot="title">{{ node.property.resultFileName }}</div>
                <span>{{ node.property.resultFileName }}</span>
              </a-tooltip>
            </div>
          </template>

          <div
            v-if="node.type === 'randomPhoneService' && node.extraInfo.count"
            class="ef-node-content-info"
          >
            生成条数：{{ node.extraInfo.count }}
          </div>
          <div
            v-if="
              node.type === 'randomPhoneService' &&
              node.extraInfo.depressOperators.length > 0
            "
            class="ef-node-content-info"
          >
            运营商过滤：{{ node.extraInfo.depressOperators }}
          </div>
          <div
            v-if="
              node.type === 'randomPhoneService' && node.extraInfo.returnType
            "
            class="ef-node-content-info"
          >
            返回类型：
            <span v-if="node.extraInfo.returnType === 'phone'">明文手机号</span>
            <span v-if="node.extraInfo.returnType === 'md5'">md5手机号</span>
          </div>
          <div
            v-if="node.type === 'fileLoadService'"
            class="ef-node-content-info"
          >
            读取选择：
            <span v-if="node.extraInfo.uploadType === 'UPLOAD'">文件上传</span>
            <span v-if="node.extraInfo.uploadType === 'LOCAL_FILE'">
              本地文件
            </span>
            <span v-if="node.extraInfo.uploadType === 'FILE_LIST'">
              文件列表
            </span>
            <span v-if="node.extraInfo.uploadType === 'FILE_SET'">
              文件集
            </span>
            <span v-if="node.extraInfo.uploadType === 'TMP_STORE'">
              临时存放读取
            </span>
            <span v-if="node.extraInfo.uploadType === 'FTP'"> FTP读取 </span>
          </div>
          <div
            v-if="
              node.type === 'fileLoadService' &&
              node.extraInfo.uploadType === 'UPLOAD' &&
              node.extraInfo.originalFileName
            "
            class="ef-node-content-info"
          >
            上传文件：
            <a-tooltip placement="left">
              <div slot="title">{{ node.extraInfo.originalFileName }}</div>
              <span>{{ node.extraInfo.originalFileName }}</span>
            </a-tooltip>
          </div>
          <div
            v-if="
              node.type === 'fileLoadService' &&
              node.extraInfo.uploadType === 'LOCAL_FILE' &&
              node.extraInfo.name
            "
            class="ef-node-content-info"
          >
            路径：{{ node.extraInfo.path }}
          </div>
          <div
            v-if="
              node.type === 'fileLoadService' &&
              node.extraInfo.uploadType === 'FILE_LIST' &&
              node.extraInfo.name
            "
            class="ef-node-content-info"
          >
            上传文件：{{ node.extraInfo.name }}
          </div>
          <div
            v-if="
              (node.type === 'fileSelectService' ||
                node.type === 'parseJobFileSelectService') &&
              node.extraInfo.fileName
            "
            class="ef-node-content-info"
          >
            上传文件：
            <span>{{ node.extraInfo.fileName }}</span>
          </div>
          <div v-if="node.type === 'sortService'" class="ef-node-content-info">
            <span>去重: {{ node.extraInfo.unique ? "是" : "否" }}</span>
            <i style="display: inline-block; margin-left: 20px"></i>
            <span>倒序: {{ node.extraInfo.reverse ? "是" : "否" }}</span>
          </div>
          <div v-if="node.type === 'sortService'" class="ef-node-content-info">
            列：{{
              node.extraInfo.columnType == "all"
                ? "全部列"
                : "指定列" + node.extraInfo.index
            }}
          </div>
          <div
            v-if="node.type === 'replaceService'"
            class="ef-node-content-info"
          >
            被替换：{{ node.extraInfo.orgContent }}
          </div>
          <div
            v-if="node.type === 'replaceService'"
            class="ef-node-content-info"
          >
            替换：{{ node.extraInfo.newContent }}
          </div>
          <div v-if="node.type === 'cutService'" class="ef-node-content-info">
            类型：{{ node.extraInfo.cutType }}
          </div>
          <div
            v-if="
              node.type === 'cutService' &&
              (node.extraInfo.cutType === '自定义' ||
                node.extraInfo.cutType === '固定长度')
            "
            class="ef-node-content-info"
          >
            参数：{{ node.extraInfo.param }}
          </div>
          <div
            v-if="
              node.type === 'cutService' &&
              node.extraInfo.cutType === '分隔截取'
            "
            class="ef-node-content-info"
          >
            下标：{{ node.extraInfo.targetIndex }}
          </div>
          <div
            v-if="node.type === 'contentFormatService'"
            class="ef-node-content-info"
          >
            参数：
            <span v-if="node.extraInfo.type === 'trim'">头尾去空</span>
            <span v-else-if="node.extraInfo.type === 'blankLine'"
              >去除空行</span
            >
            <span v-else-if="node.extraInfo.type === 'firstLine'"
              >去除第一行</span
            >
            <span v-else-if="node.extraInfo.type === 'upperCase'">大写</span>
            <span v-else-if="node.extraInfo.type === 'lowerCase'">小写</span>
            <span v-else-if="node.extraInfo.type === 'format'"
              >自定义格式化</span
            >
          </div>
          <div
            v-if="
              (node.type === 'blacklistFilterService' ||
                node.type === 'uploadBlacklistService') &&
              node.extraInfo.source
            "
            class="ef-node-content-info"
          >
            {{
              node.type == "blacklistFilterService" ? "类型" : "黑名单来源"
            }}：{{ node.extraInfo.source }}
          </div>
          <div
            v-if="
              node.type === 'uploadBlacklistService' && node.extraInfo.remark
            "
            class="ef-node-content-info"
          >
            备注：{{ node.extraInfo.remark }}
          </div>
          <div
            v-if="
              node.type === 'fileUploadCollectService' ||
              (node.type === 'fileListUploadService' && node.extraInfo.name)
            "
            class="ef-node-content-info"
          >
            名称：{{ node.extraInfo.name }}
          </div>
          <div
            v-if="
              node.type === 'fileCompressionService' &&
              node.extraInfo.password &&
              node.extraInfo.passwordSwitch
            "
            class="ef-node-content-info"
          >
            密码：{{ node.extraInfo.password }}
          </div>
          <div
            v-if="
              node.type === 'fileCompressionService' &&
              node.extraInfo.targetType
            "
            class="ef-node-content-info"
          >
            类型：{{ node.extraInfo.targetType }}
          </div>
          <div
            v-if="node.type === 'fileSplitService'"
            class="ef-node-content-info"
          >
            分割类型：
            <span v-if="node.extraInfo.splitType === 'fileNum'">文件个数</span>
            <span v-if="node.extraInfo.splitType === 'dataNum'">数据条数</span>
          </div>
          <div
            v-if="node.type === 'fileSplitService'"
            class="ef-node-content-info"
          >
            值：{{ node.extraInfo.splitNum }}
          </div>
          <div
            v-if="node.type === 'lengthFilterService'"
            class="ef-node-content-info"
          >
            长度范围：
            {{ node.extraInfo.scope }}
          </div>
          <div
            v-if="node.type === 'contentFilterService' && node.extraInfo.regex"
            class="ef-node-content-info"
          >
            正则表达式：{{ node.extraInfo.regex }}
          </div>
          <div
            v-if="
              node.type === 'phone2GuishudiService' &&
              (node.extraInfo.needProvince ||
                node.extraInfo.needCity ||
                node.extraInfo.needOperator)
            "
            class="ef-node-content-info"
          >
            参数：
            <span v-if="node.extraInfo.needProvince">省</span>
            <i
              v-if="node.extraInfo.needCity"
              style="display: inline-block; margin-left: 20px"
            ></i>
            <span v-if="node.extraInfo.needCity">市</span>
            <i
              v-if="node.extraInfo.needOperator"
              style="display: inline-block; margin-left: 20px"
            ></i>
            <span v-if="node.extraInfo.needOperator">运营商</span>
          </div>
          <div
            v-if="
              (node.type === 'databaseLoadService' ||
                node.type === 'sqlBatchQueryService' ||
                node.type === 'dataBaseUpdateService' ||
                node.type === 'databaseSelectService' ||
                node.type === 'dataBaseInsertService' ||
                node.type === 'sync2ClickhouseService' ||
                node.type == 'databaseSelectColumnSupplementService' ||
                node.type === 'database2MaxwellService') &&
              node.extraInfo.datasourceId
            "
            class="ef-node-content-info"
          >
            数据库：
            <span v-for="(item, index) in datasourceIdList" :key="index">
              <span v-if="item.id === node.extraInfo.datasourceId">{{
                item.name
              }}</span>
            </span>
          </div>
          <div
            v-if="
              (node.type === 'sync2ClickhouseService' ||
                node.type === 'dataBaseInsertService' ||
                node.type === 'database2MaxwellService') &&
              node.extraInfo.tableName
            "
            class="ef-node-content-info"
          >
            表名：{{ node.extraInfo.tableName }}
          </div>
          <div
            v-if="
              node.type === 'database2MaxwellService' &&
              node.extraInfo.condition
            "
            class="ef-node-content-info"
          >
            sql条件：{{ node.extraInfo.condition }}
          </div>
          <div
            v-if="
              ((node.type === 'databaseLoadService' &&
                node.extraInfo.active == '2') ||
                node.type === 'database2MaxwellService') &&
              node.extraInfo.tables
            "
            class="ef-node-content-info"
          >
            数据表：{{
              node.extraInfo.tablesType == "custom"
                ? node.extraInfo.tables
                : node.extraInfo.tablesType
            }}
          </div>
          <div
            v-if="node.type === 'dataBaseUpdateService'"
            class="ef-node-content-info"
          >
            更新模式：{{
              node.extraInfo.updateMode == "GENERATE_BATCH"
                ? "IN查询式更新"
                : "按行执行完整sql"
            }}
          </div>
          <div
            v-if="node.type === 'databaseSelectService'"
            class="ef-node-content-info"
          >
            查询模式：{{
              node.extraInfo.mode == "GENERATE_BATCH" ? "IN查询" : "完整sql查询"
            }}
          </div>
          <div
            v-if="
              (node.type === 'databaseLoadService' ||
                node.type === 'sqlBatchQueryService' ||
                (node.type === 'dataBaseUpdateService' &&
                  node.extraInfo.updateMode == 'GENERATE_BATCH') ||
                (node.type === 'databaseSelectService' &&
                  node.extraInfo.mode == 'GENERATE_BATCH') ||
                node.type == 'databaseSelectColumnSupplementService') &&
              node.extraInfo.sql
            "
            class="ef-node-content-info"
          >
            sql：{{ node.extraInfo.sql }}
          </div>
          <div
            v-if="
              node.type === 'databaseSelectColumnSupplementService' &&
              node.extraInfo.fillingStrWhenNotFound
            "
            class="ef-node-content-info"
          >
            未命中填充：{{ node.extraInfo.fillingStrWhenNotFound }}
          </div>
          <div
            v-if="
              node.type === 'databaseLoadService' &&
              node.extraInfo.param &&
              node.extraInfo.active == '3'
            "
            class="ef-node-content-info"
          >
            参数：{{ node.extraInfo.param }}
          </div>
          <div
            v-if="node.type === 'shellService' && node.extraInfo.cmd"
            class="ef-node-content-info"
          >
            命令：{{ node.extraInfo.cmd }}
          </div>
          <div
            v-if="
              node.type === 'prioritySelectorService' &&
              node.extraInfo.poolFileId
            "
            class="ef-node-content-info"
          >
            去重资源池：{{ node.extraInfo.poolFileName }}
          </div>
          <div
            v-if="
              node.type === 'prioritySelectorService' && node.extraInfo.count
            "
            class="ef-node-content-info"
          >
            取数数量：{{ node.extraInfo.count }}
          </div>
          <div
            v-if="
              node.type === 'quickFilterService' && node.extraInfo.columnIndex
            "
            class="ef-node-content-info"
          >
            过滤列：{{ node.extraInfo.columnIndex }}
          </div>
          <div
            v-if="
              node.type === 'quickFilterService' &&
              node.extraInfo.includeOperator.length > 0
            "
            class="ef-node-content-info"
          >
            包含运营商：{{ node.extraInfo.includeOperator }}
          </div>
          <div
            v-if="
              node.type === 'quickFilterService' && node.extraInfo.includeArea
            "
            class="ef-node-content-info"
          >
            包含区域：{{ node.extraInfo.includeArea }}
          </div>
          <div
            v-if="
              node.type === 'quickFilterService' && node.extraInfo.excludeArea
            "
            class="ef-node-content-info"
          >
            排除区域：{{ node.extraInfo.excludeArea }}
          </div>
          <div
            v-if="
              node.type === 'quickFilterService' &&
              node.extraInfo.countLimitEnabled &&
              node.extraInfo.limitCount
            "
            class="ef-node-content-info"
          >
            条数限制：{{ node.extraInfo.limitCount }}
          </div>
          <div
            v-if="node.type === 'quickFilterService' && node.extraInfo.segments"
            class="ef-node-content-info"
          >
            包含号段：{{ node.extraInfo.segments }}
          </div>
          <div
            v-if="node.type == 'columnDateFilterService'"
            class="ef-node-content-info"
          >
            日期格式:{{
              node.extraInfo.type == "yyyy-MM-dd" ? "年月日" : "年月日时分秒"
            }}
          </div>
          <div
            v-if="node.type == 'columnDateFilterService'"
            class="ef-node-content-info"
          >
            日期范围起:{{ node.extraInfo.start }}
          </div>
          <div
            v-if="node.type == 'columnDateFilterService'"
            class="ef-node-content-info"
          >
            日期范围止:{{ node.extraInfo.end }}
          </div>
          <div
            v-if="
              node.type == 'columnDateFilterService' && node.extraInfo.include
            "
            class="ef-node-content-info"
          >
            包含
          </div>
          <div
            v-if="node.type == 'columnDateFilterService'"
            class="ef-node-content-info"
          >
            日期所在列:{{ node.extraInfo.index }}
          </div>
          <div
            v-if="node.type === 'columnAppendService' && node.extraInfo.type"
            class="ef-node-content-info"
          >
            类型：
            <template v-for="item in columnAppendTypeList">
              <template v-if="item.value == node.extraInfo.type">
                {{ item.label }}
              </template>
            </template>
          </div>
          <div
            v-if="
              node.type === 'columnAppendService' &&
              node.extraInfo.initValue &&
              node.extraInfo.type == 'SERIAL_NUMBER_INCR'
            "
            class="ef-node-content-info"
          >
            初始值：{{ node.extraInfo.initValue }}
          </div>
          <div
            v-if="
              node.type === 'columnAppendService' &&
              node.extraInfo.step &&
              node.extraInfo.type == 'SERIAL_NUMBER_INCR'
            "
            class="ef-node-content-info"
          >
            间隔：{{ node.extraInfo.step }}
          </div>
          <div
            v-if="
              node.type === 'columnAppendService' &&
              node.extraInfo.content &&
              node.extraInfo.type !== 'SERIAL_NUMBER_INCR'
            "
            class="ef-node-content-info"
          >
            追加内容：{{ node.extraInfo.content }}
          </div>
          <div
            v-if="
              node.type === 'dataStatisticsService' && node.extraInfo.baseColumn
            "
            class="ef-node-content-info"
          >
            基准列：{{ node.extraInfo.baseColumn }}
          </div>
          <div
            v-if="node.type === 'textToExcelService'"
            class="ef-node-content-info"
          >
            类型：{{ node.extraInfo.type }}
          </div>
          <div
            v-if="
              node.type === 'textToExcelService' &&
              node.extraInfo.tableHeadNames
            "
            class="ef-node-content-info"
          >
            表头名：{{ node.extraInfo.tableHeadNames }}
          </div>
          <div
            v-if="
              (node.type === 'joinService' ||
                (node.type === 'cutService' &&
                  node.extraInfo.cutType === '分隔截取') ||
                node.type === 'textToExcelService' ||
                (node.type === 'zipMergeService' &&
                  node.extraInfo.type == 'excel2txt') ||
                node.type === 'excel2TxtService' ||
                node.type === 'columnRearrangeService' ||
                node.type == 'columnDateFilterService' ||
                node.type == 'shortUrlCreateService' ||
                node.type == 'dataBaseInsertService' ||
                (node.type === 'databaseLoadService' &&
                  node.extraInfo.active == '3') ||
                (node.type === 'columnAppendService' &&
                  node.extraInfo.type !== 'CONTENT') ||
                (node.type == 'dataBaseUpdateService' &&
                  node.extraInfo.updateMode == 'GENERATE_BATCH') ||
                (node.type == 'databaseSelectService' &&
                  node.extraInfo.mode == 'GENERATE_BATCH') ||
                node.type == 'dataStatisticsService' ||
                (node.type === 'contentFormatService' &&
                  node.extraInfo.type == 'format') ||
                node.type == 'columnDistinctService' ||
                node.type == 'columnSupplementService' ||
                (node.type === 'sortService' &&
                  node.extraInfo.columnType == 'assign') ||
                node.type === 'jsonFormatService' ||
                node.type === 'sync2ClickhouseService' ||
                node.type === 'columnRandomOffsetService' ||
                node.type === 'columnFilterService' ||
                node.type == 'columnGroupAndFetchService' ||
                node.type == 'variableGenerateService') &&
              node.extraInfo.separator
            "
            class="ef-node-content-info"
          >
            分隔符：{{ node.extraInfo.separator }}
          </div>
          <div
            v-if="
              node.type === 'columnFilterService' && node.extraInfo.expression
            "
            class="ef-node-content-info"
          >
            表达式：{{ node.extraInfo.expression }}
          </div>
          <div
            v-if="
              node.type === 'sync2ClickhouseService' && node.extraInfo.columns
            "
            class="ef-node-content-info"
          >
            插入列：{{ node.extraInfo.columns }}
          </div>
          <div
            v-if="node.type === 'jsonFormatService' && node.extraInfo.fields"
            class="ef-node-content-info"
          >
            字段：{{ node.extraInfo.fields }}
          </div>
          <div
            v-if="node.type === 'columnDistinctService'"
            class="ef-node-content-info"
          >
            {{ node.extraInfo.asc ? "升序" : "降序" }}
          </div>
          <div
            v-if="
              node.type === 'columnDistinctService' && node.extraInfo.sortIndex
            "
            class="ef-node-content-info"
          >
            排序列：{{ node.extraInfo.sortIndex }}
          </div>
          <div
            v-if="
              node.type === 'columnDistinctService' &&
              node.extraInfo.customerSortString
            "
            class="ef-node-content-info"
          >
            自定义排序：{{ node.extraInfo.customerSortString }}
          </div>
          <div
            v-if="
              node.type === 'columnDistinctService' &&
              node.extraInfo.distinctIndex
            "
            class="ef-node-content-info"
          >
            去重列：{{ node.extraInfo.distinctIndex }}
          </div>
          <div
            v-if="node.type === 'alarmService' && node.extraInfo.content"
            class="ef-node-content-info"
          >
            内容：{{ node.extraInfo.content }}
          </div>
          <div
            v-if="node.type === 'alarmService' && node.extraInfo.userLoginName"
            class="ef-node-content-info"
          >
            被提醒人：{{ node.extraInfo.userLoginName }}
          </div>
          <div
            v-if="
              node.type === 'pauseNotifyService' && node.extraInfo.notifyUsers
            "
            class="ef-node-content-info"
          >
            被提醒人：{{ node.extraInfo.notifyUsers }}
          </div>
          <div
            v-if="
              node.type === 'shortUrlCreateService' && node.extraInfo.domain
            "
            class="ef-node-content-info"
          >
            域名：{{ node.extraInfo.domain }}
          </div>
          <div
            v-if="
              (node.type === 'joinService' ||
                node.type === 'columnJoinFilterService') &&
              node.extraInfo.matchIndexOne
            "
            class="ef-node-content-info"
          >
            {{
              node.type === "joinService" ? "匹配下标-o1：" : "主文件列下标："
            }}
            {{ node.extraInfo.matchIndexOne }}
          </div>
          <div
            v-if="
              (node.type === 'joinService' ||
                node.type === 'columnJoinFilterService') &&
              node.extraInfo.matchIndexTwo
            "
            class="ef-node-content-info"
          >
            {{
              node.type === "joinService" ? "匹配下标-o2：" : "关联文件列下标："
            }}
            {{ node.extraInfo.matchIndexTwo }}
          </div>
          <div
            v-if="node.type === 'joinService' && node.extraInfo.outputFormat"
            class="ef-node-content-info"
          >
            输出格式：{{ node.extraInfo.outputFormat }}
          </div>
          <div
            v-if="
              node.type === 'columnRandomOffsetService' && node.extraInfo.index
            "
            class="ef-node-content-info"
          >
            下标：{{ node.extraInfo.index }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' && node.extraInfo.type
            "
            class="ef-node-content-info"
          >
            类型：{{
              node.extraInfo.type == "DATE_YYYY_MM_DD"
                ? "YYYY-MM-DD"
                : node.extraInfo.typeDesc
            }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'JSON_VALUE' &&
              node.extraInfo.fields
            "
            class="ef-node-content-info"
          >
            fields：{{ node.extraInfo.fields }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'REPLACE' &&
              node.extraInfo.replaceOld
            "
            class="ef-node-content-info"
          >
            文本替换旧值：{{ node.extraInfo.replaceOld }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'REPLACE' &&
              node.extraInfo.replaceNew
            "
            class="ef-node-content-info"
          >
            文本替换新值：{{ node.extraInfo.replaceNew }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'SPLIT' &&
              node.extraInfo.splitSeparator
            "
            class="ef-node-content-info"
          >
            split取数分隔符：{{ node.extraInfo.splitSeparator }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'SPLIT' &&
              node.extraInfo.splitIndex
            "
            class="ef-node-content-info"
          >
            split取数下标：{{ node.extraInfo.splitIndex }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'SUBSTRING' &&
              node.extraInfo.splitExpression
            "
            class="ef-node-content-info"
          >
            表达式：{{ node.extraInfo.splitExpression }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'COLUMN_SUPPLEMENT' &&
              node.extraInfo.length
            "
            class="ef-node-content-info"
          >
            补充到多少长度：{{ node.extraInfo.length }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'COLUMN_SUPPLEMENT' &&
              node.extraInfo.chars
            "
            class="ef-node-content-info"
          >
            补充字符：{{ node.extraInfo.chars }}
          </div>
          <div
            v-if="
              node.type === 'columnConversionService' &&
              node.extraInfo.type == 'DATE_CALCULATE' &&
              node.extraInfo.offset
            "
            class="ef-node-content-info"
          >
            偏移量：{{ node.extraInfo.offset }}
          </div>
          <div
            v-if="
              node.type === 'columnAppendService' &&
              node.extraInfo.type !== 'TAG'
            "
            class="ef-node-content-info"
          >
            {{ node.extraInfo.appendAtLineHead ? "追加到行首" : "追加到行尾" }}
          </div>
          <div
            v-if="
              node.type === 'columnAppendService' &&
              node.extraInfo.type == 'TAG' &&
              node.extraInfo.customerCodeIndex
            "
            class="ef-node-content-info"
          >
            客户编号列： {{ node.extraInfo.customerCodeIndex }}
          </div>
          <div
            v-if="
              node.type === 'parseJobPipelineService' &&
              node.extraInfo.requestId
            "
            class="ef-node-content-info"
          >
            请求编号：{{ node.extraInfo.requestId }}
          </div>
          <div
            v-if="
              node.type === 'parseJobPipelineService' &&
              node.extraInfo.accessKey
            "
            class="ef-node-content-info"
          >
            accessKey：{{ node.extraInfo.accessKey }}
          </div>
          <div
            v-if="
              node.type === 'parseJobPipelineService' && node.extraInfo.taskType
            "
            class="ef-node-content-info"
          >
            类型：{{ node.extraInfo.taskType }}
          </div>
          <div
            v-if="
              node.type === 'parseJobPipelineService' &&
              node.extraInfo.hitRate &&
              viewOnly
            "
            class="ef-node-content-info"
          >
            可用率：{{ node.extraInfo.hitRate }}
          </div>
          <div v-if="node.type === 'diffService'" class="ef-node-content-info">
            是否启用列去重：{{ node.extraInfo.enableColumnDiff ? "是" : "否" }}
          </div>
          <div
            v-if="
              node.type === 'diffService' || node.type == 'intersectionService'
            "
            class="ef-node-content-info"
          >
            是否启用类型检验：{{ node.extraInfo.checkFileType ? "是" : "否" }}
          </div>
          <div
            v-if="node.type === 'zipMergeService' && node.extraInfo.type"
            class="ef-node-content-info"
          >
            类型：{{ node.extraInfo.type }}
          </div>
          <div
            v-if="node.type === 'zipMergeService'"
            class="ef-node-content-info"
          >
            追加文件名：{{ node.extraInfo.appendFileName ? "追加" : "不追加" }}
          </div>
          <div
            v-if="
              node.type === 'zipMergeService' ||
              node.type === 'excel2TxtService'
            "
            class="ef-node-content-info"
          >
            移除头部行数：{{ node.extraInfo.removeHeaderLineCount }}
          </div>
          <div
            v-if="node.type === 'zipMergeService'"
            class="ef-node-content-info"
          >
            密码：{{ node.extraInfo.unzipPassword }}
          </div>
          <div
            v-if="
              node.type === 'columnGroupService' && node.extraInfo.delimiter
            "
            class="ef-node-content-info"
          >
            分组分隔符：{{ node.extraInfo.delimiter }}
          </div>
          <div
            v-if="
              node.type === 'columnGroupService' && node.extraInfo.groupIndex
            "
            class="ef-node-content-info"
          >
            分组下标：{{ node.extraInfo.groupIndex }}
          </div>
          <div
            v-if="node.type === 'columnGroupService'"
            class="ef-node-content-info"
          >
            移除分组列：{{ node.extraInfo.removeColumn ? "移除" : "保留" }}
          </div>
          <div
            v-if="
              node.type === 'contentAppendService' && node.extraInfo.fileName
            "
            class="ef-node-content-info"
          >
            被追加文件：{{ node.extraInfo.fileName }}
          </div>
          <div
            v-if="node.type === 'contentAppendService'"
            class="ef-node-content-info"
          >
            追加后去重：{{ node.extraInfo.unique ? "是" : "否" }}
          </div>
          <div
            v-if="node.type === 'contentAppendService'"
            class="ef-node-content-info"
          >
            覆盖源文件：{{ node.extraInfo.rewrite ? "是" : "否" }}
          </div>
          <div
            v-if="node.type === 'shufSelectorService'"
            class="ef-node-content-info"
          >
            类型：{{ node.extraInfo.type == "constant" ? "固定数" : "比例" }}
          </div>
          <div
            v-if="
              node.type === 'shufSelectorService' &&
              ((node.extraInfo.count && node.extraInfo.type == 'constant') ||
                (node.extraInfo.ratio && node.extraInfo.type == 'ratio'))
            "
            class="ef-node-content-info"
          >
            条数：{{
              node.extraInfo.type == "constant"
                ? node.extraInfo.count
                : node.extraInfo.ratio + "%"
            }}
          </div>
          <div
            v-if="
              (node.type === 'manualInputService' ||
                node.type === 'variableExtractService') &&
              node.extraInfo.content
            "
            class="ef-node-content-info"
          >
            内容：{{ node.extraInfo.content }}
          </div>
          <div
            v-if="node.type === 'httpSenderService' && node.extraInfo.url"
            class="ef-node-content-info"
          >
            接口地址：{{ node.extraInfo.url }}
          </div>
          <div
            v-if="node.type === 'httpSenderService' && node.extraInfo.status"
            class="ef-node-content-info"
          >
            状态码：{{ node.extraInfo.status }}
          </div>
          <div
            v-if="node.type === 'httpSenderService' && node.extraInfo.message"
            class="ef-node-content-info"
          >
            接口提示：{{ node.extraInfo.message }}
          </div>
          <div
            v-if="
              (node.type === 'httpReceiverService' ||
                node.type === 'apiLogToDzDkDataService') &&
              node.extraInfo.requestId
            "
            class="ef-node-content-info"
          >
            请求编号：{{ node.extraInfo.requestId }}
          </div>
          <div
            v-if="node.type === 'delayService' && node.extraInfo.delayType"
            class="ef-node-content-info"
          >
            延迟类型：{{
              node.extraInfo.delayType == "FIXED_SECONDS"
                ? "固定秒数"
                : "指定日期"
            }}
          </div>
          <div
            v-if="
              node.type === 'delayService' &&
              node.extraInfo.delaySecond &&
              node.extraInfo.delayType == 'FIXED_SECONDS'
            "
            class="ef-node-content-info"
          >
            延迟秒数：{{ node.extraInfo.delaySecond }}
          </div>
          <div
            v-if="
              node.type === 'delayService' &&
              node.extraInfo.executeDateTime &&
              node.extraInfo.delayType == 'FIXED_DATE'
            "
            class="ef-node-content-info"
          >
            执行时间：{{ node.extraInfo.executeDateTime }}
          </div>
          <div v-if="node.type === 'delayService'" class="ef-node-content-info">
            跳过空文件：{{ node.extraInfo.skipEmptyFile ? "是" : "否" }}
          </div>
          <div
            v-if="node.type === 'multipleColumn2OneService'"
            class="ef-node-content-info"
          >
            是否过滤空行：{{ node.extraInfo.filterBlankLine ? "是" : "否" }}
          </div>
          <div
            v-if="node.type === 'ftpService' && node.extraInfo.host"
            class="ef-node-content-info"
          >
            主机：{{ node.extraInfo.host }}
          </div>
          <div
            v-if="node.type === 'ftpService' && node.extraInfo.path"
            class="ef-node-content-info"
          >
            上传路径：{{ node.extraInfo.path }}
          </div>
          <div
            v-if="
              (node.type === 'ftpService' ||
                node.type == 'uploadFileSystemService') &&
              node.extraInfo.fileName
            "
            class="ef-node-content-info"
          >
            文件名称：{{ node.extraInfo.fileName }}
          </div>
          <div
            v-if="node.type == 'uploadFileSystemService'"
            class="ef-node-content-info"
          >
            文件空间：
            <template v-for="item in fileSpaceList">
              <template v-if="item.id == node.extraInfo.fileSpaceId">
                {{ item.name }}
              </template>
            </template>
          </div>
          <div
            v-if="
              node.type === 'fileUploadCollectService' ||
              node.type === 'fileListUploadService' ||
              node.type == 'uploadFileSystemService'
            "
            class="ef-node-content-info"
          >
            过期时间：{{
              node.extraInfo.expireDays == 0
                ? "永不过期"
                : node.extraInfo.expireDays + "天"
            }}
          </div>
          <div
            v-if="node.type == 'uploadFileSystemService'"
            class="ef-node-content-info"
          >
            {{ node.extraInfo.zip ? "压缩" : "" }}
          </div>
          <div
            v-if="node.type === 'ftpService' && node.extraInfo.username"
            class="ef-node-content-info"
          >
            用户名：{{ node.extraInfo.username }}
          </div>
          <div
            v-if="node.type === 'ftpService' && node.extraInfo.type"
            class="ef-node-content-info"
          >
            类型：{{ node.extraInfo.type }}
          </div>
          <div
            v-if="node.type === 'ftpService' && node.extraInfo.port"
            class="ef-node-content-info"
          >
            端口：{{ node.extraInfo.port }}
          </div>
          <div
            v-if="node.type === 'sqlBatchQueryService'"
            class="ef-node-content-info"
          >
            分隔符：{{ node.extraInfo.split }}
          </div>
          <div
            v-if="node.type === 'columnRearrangeService'"
            class="ef-node-content-info"
          >
            重排顺序：{{ node.extraInfo.order }}
          </div>
          <div
            v-if="
              node.type === 'sipCallTaskCreateService' &&
              node.extraInfo.taskName
            "
            class="ef-node-content-info"
          >
            任务名称：{{ node.extraInfo.taskName }}
          </div>
          <div
            v-if="
              node.type === 'sipCallTaskCreateService' &&
              node.extraInfo.projectToken
            "
            class="ef-node-content-info"
          >
            项目token：{{ node.extraInfo.projectToken }}
          </div>
          <div
            v-if="node.type === 'ossUploadService' && node.extraInfo.bucketName"
            class="ef-node-content-info"
          >
            bucket：{{ node.extraInfo.bucketName }}
          </div>
          <div
            v-if="node.type === 'ossUploadService' && node.extraInfo.filePath"
            class="ef-node-content-info"
          >
            文件路径：{{ node.extraInfo.filePath }}
          </div>
          <div
            v-if="
              node.type === 'ossUploadService' && node.extraInfo.storageType
            "
            class="ef-node-content-info"
          >
            存储类型：{{
              node.extraInfo.storageType == "Standard" ? "标准存储" : "归档存储"
            }}
          </div>
          <div
            v-if="
              node.type === 'logicViewSynService' && node.extraInfo.viewCode
            "
            class="ef-node-content-info"
          >
            视图编号：{{ node.extraInfo.viewCode }}
          </div>
          <div
            v-if="
              node.type === 'addShortUrlGroupService' &&
              node.extraInfo.activityCode
            "
            class="ef-node-content-info"
          >
            短链名称：{{ node.extraInfo.activityCode }}
          </div>
          <div
            v-if="
              node.type === 'addShortUrlGroupService' &&
              node.extraInfo.longUrl &&
              node.extraInfo.active == '1'
            "
            class="ef-node-content-info"
          >
            长链：{{ node.extraInfo.longUrl }}
          </div>
          <div
            v-if="
              node.type === 'addShortUrlGroupService' &&
              node.extraInfo.longUrlGroupId &&
              node.extraInfo.active == '2'
            "
            class="ef-node-content-info"
          >
            长链分组：{{ node.extraInfo.longUrlGroupId }}
          </div>
          <div
            v-if="
              node.type === 'addShortUrlGroupService' && node.extraInfo.domain
            "
            class="ef-node-content-info"
          >
            域名：{{ node.extraInfo.domain }}
          </div>
          <div
            v-if="
              node.type === 'addShortUrlGroupService' && node.extraInfo.category
            "
            class="ef-node-content-info"
          >
            业务类型：{{ node.extraInfo.category }}
          </div>
          <div
            v-if="node.type === 'addShortUrlGroupService'"
            class="ef-node-content-info"
          >
            空号检测：{{ node.extraInfo.blankNumberCheck ? "是" : "否" }}
          </div>
          <div
            v-if="
              node.type === 'addShortUrlGroupService' &&
              node.extraInfo.expireRule
            "
            class="ef-node-content-info"
          >
            过期策略：
            <template v-if="node.extraInfo.expireRule == 'UN_EXPIRE'">
              不设置过期
            </template>
            <template
              v-if="node.extraInfo.expireRule == 'EXPIRE_AFTER_FIXED_SECONDS'"
            >
              固定过期时间
            </template>
            <template
              v-if="node.extraInfo.expireRule == 'EXPIRE_AFTER_ONE_CLICK'"
            >
              点击一次后过期
            </template>
          </div>
          <div
            v-if="
              node.type === 'addShortUrlGroupService' &&
              node.extraInfo.expireTime &&
              node.extraInfo.expireRule !== 'UN_EXPIRE'
            "
            class="ef-node-content-info"
          >
            过期时间：{{ node.extraInfo.expireTime }}
            {{ node.extraInfo.expireUnit }}
          </div>
          <div
            v-if="node.type === 'dataFilterBaseAction' && node.extraInfo.action"
            class="ef-node-content-info"
          >
            过滤类型：{{ node.extraInfo.action }}
          </div>
          <div
            v-if="node.type === 'inputFileDataCheckService'"
            class="ef-node-content-info"
          >
            列选择：{{
              node.extraInfo.columnType == "ALL_COLUMNS" ? "所有列" : "指定列"
            }}
          </div>
          <div
            v-if="
              node.type === 'inputFileDataCheckService' &&
              node.extraInfo.columnType == 'INDEX_COLUMNS'
            "
            class="ef-node-content-info"
          >
            列下标：{{ node.extraInfo.columnIndex }}
          </div>
          <div
            v-if="
              node.type === 'inputFileDataCheckService' &&
              node.extraInfo.passRate
            "
            class="ef-node-content-info"
          >
            通过率：{{ node.extraInfo.passRate }}%
          </div>
          <div
            v-if="
              node.type === 'uploadXinBaService' ||
              node.type == 'uploadQuDianService'
            "
            class="ef-node-content-info"
          >
            任务名称：{{ node.extraInfo.taskName }}
          </div>
          <div
            v-if="node.type === 'uploadXinBaService' && node.extraInfo.type"
            class="ef-node-content-info"
          >
            业务类型：{{ node.extraInfo.type }}
          </div>
          <div
            v-if="
              (node.type === 'uploadXinBaService' ||
                node.type == 'uploadQuDianService') &&
              node.extraInfo.taskTemplateTitle
            "
            class="ef-node-content-info"
          >
            任务模板：{{ node.extraInfo.taskTemplateTitle }}
          </div>
          <div
            v-if="
              node.type === 'uploadXinBaService' ||
              node.type == 'uploadQuDianService'
            "
            class="ef-node-content-info"
          >
            上传短链：{{ node.extraInfo.uploadShortUrl ? "是" : "否" }}
          </div>
          <div
            v-if="node.type === 'kafkaReceiverService' && node.extraInfo.topic"
            class="ef-node-content-info"
          >
            topic：{{ node.extraInfo.topic }}
          </div>
          <div
            v-if="
              (node.type == 'fileStoreService' ||
                (node.type === 'fileLoadService' &&
                  node.extraInfo.uploadType == 'TMP_STORE')) &&
              node.extraInfo.tag
            "
            class="ef-node-content-info"
          >
            标签：{{ node.extraInfo.tag }}
          </div>
          <div
            v-if="
              node.type === 'fileLoadService' &&
              node.extraInfo.uploadType == 'FTP' &&
              node.extraInfo.ftpName
            "
            class="ef-node-content-info"
          >
            ftp账号：{{ node.extraInfo.ftpName }}
          </div>
          <div
            v-if="
              node.type === 'fileLoadService' &&
              node.extraInfo.uploadType == 'FTP' &&
              node.extraInfo.appendFileName
            "
            class="ef-node-content-info"
          >
            追加文件名
          </div>
          <div
            v-if="node.type === 'zipService' && node.extraInfo.password"
            class="ef-node-content-info"
          >
            密码：{{ node.extraInfo.password }}
          </div>
          <div
            v-if="
              (node.type === 'limiterService' ||
                node.type === 'concurrentLimitService') &&
              node.extraInfo.key
            "
            class="ef-node-content-info"
          >
            业务Key：{{ node.extraInfo.key }}
          </div>
          <div
            v-if="
              (node.type === 'limiterService' ||
                node.type === 'concurrentLimitService') &&
              node.extraInfo.count
            "
            class="ef-node-content-info"
          >
            {{ node.type === "limiterService" ? "每日总量" : "并发数" }}：{{
              node.extraInfo.count
            }}
          </div>
          <div
            v-if="node.type === 'rowAppendService' && node.extraInfo.content"
            class="ef-node-content-info"
          >
            追加内容：{{ node.extraInfo.content }}
          </div>
          <div
            v-if="
              node.type === 'contentSupplementService' && node.extraInfo.total
            "
            class="ef-node-content-info"
          >
            数据总量：{{ node.extraInfo.total }}
          </div>
          <div v-if="node.type === 'mergeService'" class="ef-node-content-info">
            是否去重：{{ node.extraInfo.distinct ? "是" : "否" }}
          </div>
          <div
            v-if="
              node.type === 'sortService' ||
              node.type === 'intersectionService' ||
              node.type === 'mergeService' ||
              node.type === 'joinService'
            "
            class="ef-node-content-info"
          >
            使用spark：{{ node.extraInfo.useSpark ? "是" : "否" }}
          </div>
          <div
            v-if="
              node.type === 'maxwellFilterService' && node.extraInfo.database
            "
            class="ef-node-content-info"
          >
            关注数据库：<span
              v-for="(item, index) in datasourceIdList"
              :key="index"
            >
              <span v-if="item.id === node.extraInfo.datasourceId">{{
                item.name
              }}</span>
            </span>
          </div>
          <div
            v-if="node.type === 'maxwellFilterService' && node.extraInfo.table"
            class="ef-node-content-info"
          >
            关注表：{{ node.extraInfo.table }}
          </div>
          <div
            v-if="node.type === 'maxwellFilterService' && node.extraInfo.types"
            class="ef-node-content-info"
          >
            事件类型：
            <template v-for="item in typesOptions">
              <template v-for="type in node.extraInfo.types.split(',')">
                <template v-if="item.value == type">
                  {{ item.label }}
                </template>
              </template>
            </template>
          </div>
          <div
            v-if="
              node.type === 'maxwellFilterService' &&
              node.extraInfo.filterColumnSwitch
            "
            class="ef-node-content-info"
          >
            值过滤：{{ node.extraInfo.filterColumn }} 等于
            {{ node.extraInfo.filterColumnValue }}
          </div>
          <div
            v-if="node.type == 'streamSpeedMonitorService' && speed"
            class="ef-node-content-info"
          >
            速率：{{ speed.TPS }}/s
          </div>
          <div
            v-if="node.type == 'streamSpeedMonitorService' && speed"
            class="ef-node-content-info"
          >
            压力：<a-tooltip placement="left">
              <div slot="title">
                最近一分钟处理量: {{ speed.RECENT_MINUTE_1 }}
                <br />
                最近五分钟处理量: {{ speed.RECENT_MINUTE_5 }}
                <br />
                最近十分钟处理量: {{ speed.RECENT_MINUTE_10 }}
              </div>
              <span
                >{{ speed.RECENT_MINUTE_1Thousand }}
                {{ speed.RECENT_MINUTE_5Thousand }}
                {{ speed.RECENT_MINUTE_10Thousand }}</span
              >
            </a-tooltip>
          </div>
          <div
            v-if="node.type == 'streamContentMonitorService'"
            class="ef-node-content-info"
          >
            查看内容：
            <a-button type="link" @click.stop="loadDynamicApiData('content')">
              {{ contentSize }}
            </a-button>
          </div>
          <div
            v-if="node.type == 'smsCustomerCodeFetchService'"
            class="ef-node-content-info"
          >
            数据：{{
              node.extraInfo.fetchSmsSendData ? "发送数据" : "点击数据"
            }}
          </div>
          <div
            v-if="
              node.type == 'smsCustomerCodeFetchService' &&
              node.extraInfo.fetchSmsSendData
            "
            class="ef-node-content-info"
          >
            状态过滤：{{ node.extraInfo.success ? "发送成功" : "全部状态" }}
          </div>
          <div
            v-if="
              node.type === 'columnRandomOffsetService' &&
              node.extraInfo.columnType
            "
            class="ef-node-content-info"
          >
            列类型：{{
              node.extraInfo.columnType == "number" ? "数值" : "日期(年月日)"
            }}
          </div>
          <div
            v-if="
              node.type === 'columnRandomOffsetService' &&
              node.extraInfo.minOffset &&
              node.extraInfo.maxOffset
            "
            class="ef-node-content-info"
          >
            范围：{{ node.extraInfo.minOffset }} 到
            {{ node.extraInfo.maxOffset }}
          </div>
          <div
            v-if="
              node.type === 'maxwell2DatabaseService' &&
              node.extraInfo.sourceDatabaseName
            "
            class="ef-node-content-info"
          >
            原数据库名：{{ node.extraInfo.sourceDatabaseName }}
          </div>
          <div
            v-if="
              node.type === 'maxwell2DatabaseService' &&
              node.extraInfo.sourceTableName
            "
            class="ef-node-content-info"
          >
            原表名：{{ node.extraInfo.sourceTableName }}
          </div>
          <div
            v-if="
              node.type === 'maxwell2DatabaseService' &&
              node.extraInfo.sinkDatabaseName
            "
            class="ef-node-content-info"
          >
            下游数据源：{{ node.extraInfo.sinkDatabaseName }}
          </div>
          <div
            v-if="
              node.type === 'maxwell2DatabaseService' &&
              node.extraInfo.sinkTableName
            "
            class="ef-node-content-info"
          >
            下游表名：{{ node.extraInfo.sinkTableName }}
          </div>
          <div
            v-if="
              (node.type === 'repeatFilterService' ||
                node.type === 'repeatCountAnalysisService') &&
              node.extraInfo.splitCount
            "
            class="ef-node-content-info"
          >
            分割文件数：{{ node.extraInfo.splitCount }}
          </div>
          <div
            v-if="node.type === 'tagFilterService' && node.extraInfo.tagContent"
            class="ef-node-content-info"
          >
            标签内容：{{ node.extraInfo.tagContent }}
          </div>
          <div
            v-if="node.type === 'ai00166Service' && node.extraInfo.ruleDk"
            class="ef-node-content-info"
          >
            去重规则：{{ node.extraInfo.ruleDk }}
          </div>
          <div
            v-if="node.type === 'ai00166Service' && node.extraInfo.period"
            class="ef-node-content-info"
          >
            去重周期：{{ node.extraInfo.period }}
          </div>
          <div
            v-if="node.type === 'ai00166Service' && node.extraInfo.filePath"
            class="ef-node-content-info"
          >
            ftp文件路径：{{ node.extraInfo.filePath }}
          </div>
          <div
            v-if="node.type === 'uploadMonitorService' && node.extraInfo.code"
            class="ef-node-content-info"
          >
            code：{{ node.extraInfo.code }}
          </div>
          <div
            v-if="node.type === 'uploadMonitorService' && node.extraInfo.status"
            class="ef-node-content-info"
          >
            状态：{{ node.extraInfo.status == "SUCCESS" ? "正常" : "异常" }}
          </div>
          <div
            v-if="
              node.type === 'distributionStatisticsService' &&
              node.extraInfo.start
            "
            class="ef-node-content-info"
          >
            起始于：{{ node.extraInfo.start }}
          </div>
          <div
            v-if="
              node.type === 'distributionStatisticsService' &&
              node.extraInfo.end
            "
            class="ef-node-content-info"
          >
            终止于：{{ node.extraInfo.end }}
          </div>
          <div
            v-if="
              node.type === 'distributionStatisticsService' &&
              node.extraInfo.step
            "
            class="ef-node-content-info"
          >
            步长：{{ node.extraInfo.step }}
          </div>
          <div
            v-if="
              node.type === 'distributionStatisticsService' &&
              node.extraInfo.specialGape
            "
            class="ef-node-content-info"
          >
            单独区间：{{ node.extraInfo.specialGape }}
          </div>
          <div
            v-if="
              node.type === 'columnGroupAndFetchService' &&
              node.extraInfo.groupIndex
            "
            class="ef-node-content-info"
          >
            分组列：{{ node.extraInfo.groupIndex }}
          </div>
          <div
            v-if="
              node.type === 'columnGroupAndFetchService' && node.extraInfo.count
            "
            class="ef-node-content-info"
          >
            条数：{{ node.extraInfo.count }}
          </div>
          <div
            v-if="
              node.type === 'columnMatchAndFetchService' &&
              node.extraInfo.mainFileColumns
            "
            class="ef-node-content-info"
          >
            主文件列：{{ node.extraInfo.mainFileColumns }}
          </div>
          <div
            v-if="
              node.type === 'columnMatchAndFetchService' &&
              node.extraInfo.matchFileColumns
            "
            class="ef-node-content-info"
          >
            匹配文件列：{{ node.extraInfo.matchFileColumns }}
          </div>
          <div
            v-if="
              node.type === 'createFormTaskService' && node.extraInfo.formName
            "
            class="ef-node-content-info"
          >
            模板：{{ node.extraInfo.formName }}
          </div>
          <div
            v-if="
              node.type === 'createFormTaskService' && node.extraInfo.taskName
            "
            class="ef-node-content-info"
          >
            关联任务：
            <a-tooltip placement="left">
              <span slot="title"> 跳转到关联任务 </span>
              <a href="javascript:;" @click.stop="jumpPipelineList">
                {{ node.extraInfo.taskName }}
              </a>
            </a-tooltip>
          </div>
          <div
            v-if="
              node.type === 'phoneGenerateService' && node.extraInfo.segments
            "
            class="ef-node-content-info"
          >
            所需生成号段：{{ node.extraInfo.segments }}
          </div>
          <div
            v-if="
              node.type === 'phoneGenerateService' && node.extraInfo.returnType
            "
            class="ef-node-content-info"
          >
            返回类型：
            <span v-if="node.extraInfo.returnType === 'PHONE'">明文手机号</span>
            <span v-if="node.extraInfo.returnType === 'MD5'">md5手机号</span>
            <span v-if="node.extraInfo.returnType === 'CUSTOMER_CODE'"
              >客户编号</span
            >
          </div>
          <div
            v-if="
              node.type === 'upload2OmegaMidTableService' &&
              node.extraInfo.midTableName
            "
            class="ef-node-content-info"
          >
            中间表：{{ node.extraInfo.midTableName }}
          </div>
          <div
            v-if="node.dynamicUpdateProgress && node.state == 'RUNNING'"
            class="ef-node-content-info"
          >
            <a-progress
              :percent="taskProgress"
              strokeColor="#67C23A"
            ></a-progress>
          </div>
          <div v-if="node.state !== 'WAITING'" class="ef-node-content-info">
            耗时：
            <span>{{ $common.timeConsuming(node.costSeconds) }}</span>
          </div>
          <div
            v-if="
              node.state !== 'WAITING' &&
              node.state !== 'RUNNING' &&
              node.type !== 'fileCompressionService'
            "
            class="ef-node-content-info"
          >
            总条数：<span v-if="node.totalCount == -1">未知</span>
            <a-tooltip placement="left" v-else>
              <div slot="title">
                {{ node.totalCount }}
              </div>
              <span>{{ $common.formatTotalCount(node.totalCount) }}</span>
            </a-tooltip>
          </div>
        </div>
      </a-tooltip>
      <div
        class="endpoint flow-node-drag"
        :class="{ 'endpoint-active': endpointShow }"
        :style="{ backgroundColor: getColorShow ? getColor : '' }"
        v-if="isStartNode && !viewOnly"
        @click.stop="endpointNodeMenu"
        @mousedown="endpointShow = true"
      ></div>
    </div>

    <a-modal
      title="设置变量参数"
      v-model="listParameterShow"
      :maskClosable="false"
      class="action-class"
      width="1000px"
      :footer="false"
    >
      <a-button
        @click="addParameter"
        type="primary"
        style="margin-bottom: 10px"
        :disabled="viewOnly"
      >
        新增参数
      </a-button>
      <a-table
        :rowClassName="$common.rowClassColor"
        bordered
        :components="$common.getTitle(columnsParameter)"
        :columns="columnsParameter"
        :dataSource="listParameter"
        :pagination="false"
        :loading="loadingTable"
        size="small"
      >
        <span slot="input" slot-scope="text, record">
          {{ record.input ? "输入文件" : "输出文件" }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a
            href="javascript:;"
            @click="editParameter(record)"
            :disabled="viewOnly"
            >修改</a
          >
          <a-divider type="vertical" />
          <a-popconfirm
            placement="left"
            okText="确认"
            cancelText="取消"
            @confirm="deleteParameter(record)"
            :disabled="viewOnly"
          >
            <template slot="title">确认是否删除{{ record.name }}</template>
            <a href="javascript:;" style="color: #ff4d4f" :disabled="viewOnly"
              >删除</a
            >
          </a-popconfirm>
        </span>
      </a-table>
    </a-modal>

    <a-modal
      :title="updateParameterTitle"
      v-model="updateParameterShow"
      :maskClosable="false"
      class="action-class"
      width="500px"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="参数名称:">
          <a-input
            v-model="parameterName"
            placeholder="请输入参数名称,格式:${xxx}"
          />
        </a-form-item>
        <a-form-item label="参数值:">
          <a-select
            v-model="parameterValue"
            placeholder="请选择"
            style="width: 100%"
          >
            <a-select-option
              v-for="item in parameterValueSelectList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
          <a-input
            v-model="parameterCustomValue"
            v-if="parameterValue == ''"
            placeholder="请输入参数值"
            style="margin-top: 10px"
          />
        </a-form-item>
        <a-form-item label="备注:">
          <a-input v-model="parameterRemark" placeholder="请输入备注" />
        </a-form-item>
        <a-form-item
          label="文件类型:"
          v-if="getMenuName !== '零对一' && getMenuName !== '一对零'"
        >
          <a-switch
            class="greenSwitch"
            checked-children="输入文件"
            un-checked-children="输出文件"
            v-model="parameterInputSwitch"
            @change="parameterTag = ''"
          />
        </a-form-item>
        <a-form-item
          label="标签:"
          v-if="
            getMenuName == '零对一' ||
            getMenuName == '一对一' ||
            getMenuName == '一对零'
              ? false
              : getMenuName == '多对一'
              ? parameterInputSwitch
              : getMenuName == '一对多'
              ? !parameterInputSwitch
              : true
          "
        >
          <a-input v-model="parameterTag" placeholder="请输入标签" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="updateParameterShow = false">取 消</a-button>
          <a-button type="primary" @click="updateParameterSubmit"
            >确 定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="查看内容"
      v-model="contentShow"
      :maskClosable="false"
      class="action-class"
      width="800px"
    >
      <div class="searchButton">
        <a-button
          type="primary"
          @click="loadDynamicApiData('content')"
          icon="reload"
          >刷新</a-button
        >
      </div>
      <div class="contentList" v-if="contentList.length > 0">
        <div
          class="contentItem"
          v-for="(item, index) in contentList"
          :key="index"
        >
          <div class="content" :title="item">{{ item }}</div>
          <a-button type="link" v-copy="item" class="button">复制</a-button>
        </div>
      </div>
      <div v-else>暂无内容</div>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button @click="contentShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: [
    "node",
    "activeElement",
    "viewOnly",
    "composeWorkflowTaskList",
    "data",
    "taskId",
    "menuList",
    "errorNodeId",
    "model",
    "datasourceIdList",
    "fileSpaceList",
    "runningNodeId",
  ],
  data() {
    return {
      uploadTxt: "",
      endpointShow: false,
      getColorShow: false,
      listParameter: [],
      loadingTable: false,
      columnsParameter: [
        {
          title: "参数名称",
          ellipsis: true,
          dataIndex: "name",
          width: 200,
        },
        {
          title: "参数值",
          ellipsis: true,
          dataIndex: "value",
          width: 200,
        },
        {
          title: "文件类型",
          ellipsis: true,
          dataIndex: "input",
          scopedSlots: { customRender: "input" },
          width: 200,
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "tag",
          width: 200,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
        },
      ],
      listParameterShow: false,
      updateParameterTitle: "",
      updateParameterShow: false,
      parameterId: "",
      parameterName: "",
      parameterValue: undefined,
      parameterCustomValue: "",
      parameterValueSelectList: [
        { id: 1, label: "原始文件名", value: "${source_file_name}" },
        { id: 2, label: "文件行数", value: "${line_count}" },
        { id: 3, label: "文件列数", value: "${column_count}" },
        { id: 3, label: "两位递增序号(日期)", value: "${daily_sequence_01}" },
        { id: 3, label: "四位递增序号(日期)", value: "${daily_sequence_0001}" },
        { id: 4, label: "自定义", value: "" },
      ],
      parameterRemark: "",
      parameterTag: "",
      parameterInputSwitch: false,
      columnAppendTypeList: [
        { id: 1, label: "普通文本", value: "CONTENT" },
        { id: 2, label: "列计算", value: "CALCULATE" },
        { id: 3, label: "列计算(4位小数)", value: "CALCULATE_4F" },
        { id: 4, label: "序号递增", value: "SERIAL_NUMBER_INCR" },
        { id: 5, label: "顺序取值", value: "ORDER_ACCESS" },
        { id: 6, label: "随机取值", value: "RANDOM_ACCESS" },
        { id: 7, label: "列拼接", value: "COLUMN_CONNECT" },
        { id: 8, label: "动态列", value: "DYNAMIC_COLUMN_CONNECT" },
        { id: 9, label: "特定标签", value: "TAG" },
      ],
      typesOptions: [
        { label: "新增", value: "insert" },
        { label: "更新", value: "update" },
        { label: "删除", value: "delete" },
      ],
      speed: {},
      speedTimer: null,
      contentTimer: null,
      contentList: "",
      contentSize: 0,
      contentShow: false,
    };
  },
  computed: {
    priorityFileListFilter() {
      let arr = [];
      this.node.extraInfo.priorityFileList.map((item, index) => {
        arr[index] = item.fileId;
      });
      return arr;
    },
    taskProgress() {
      let progress = 0;
      if (this.node.totalCount) {
        progress = (this.node.completeCount / this.node.totalCount) * 100;
        progress = Math.floor(progress);
      }
      return progress;
    },
    nodeLeftStyle() {
      if (this.errorNodeId && this.errorNodeId == this.node.id) {
        return {
          "ef-node-left-error": true,
        };
      } else {
        let isStyle = true;
        if (this.node.extraInfoShow) {
          switch (this.node.type) {
            case "fileLoadService":
              isStyle = true;
              if (this.node.extraInfo.uploadType === "UPLOAD") {
                if (
                  !this.node.extraInfo.originalFileName ||
                  !this.node.extraInfo.path
                ) {
                  isStyle = false;
                }
              } else if (this.node.extraInfo.uploadType === "LOCAL_FILE") {
                if (!this.node.extraInfo.path) {
                  isStyle = false;
                }
              } else if (this.node.extraInfo.uploadType === "TMP_STORE") {
                if (
                  !this.node.extraInfo.tag ||
                  !this.node.extraInfo.tmpStoreCode
                ) {
                  isStyle = false;
                }
              } else if (this.node.extraInfo.uploadType === "FTP") {
                if (
                  !this.node.extraInfo.ftpId ||
                  this.node.extraInfo?.ftpPathList.length == 0
                ) {
                  isStyle = false;
                }
              } else {
                if (!this.node.extraInfo.id) {
                  isStyle = false;
                }
              }
              break;
            case "databaseLoadService":
              isStyle = true;
              if (this.node.extraInfo.active == "1") {
                if (
                  !this.node.extraInfo.datasourceId ||
                  !this.node.extraInfo.sql
                ) {
                  isStyle = false;
                }
              } else if (this.node.extraInfo.active == "2") {
                if (
                  !this.node.extraInfo.datasourceId ||
                  !this.node.extraInfo.sql ||
                  !this.node.extraInfo.tables
                ) {
                  isStyle = false;
                }
              } else {
                if (
                  !this.node.extraInfo.datasourceId ||
                  !this.node.extraInfo.sql ||
                  !this.node.extraInfo.separator ||
                  !this.node.extraInfo.param
                ) {
                  isStyle = false;
                }
              }
              break;
            case "replaceService":
              isStyle = true;
              if (!this.node.extraInfo.orgContent) {
                isStyle = false;
              }
              break;
            case "cutService":
              isStyle = true;
              if (!this.node.extraInfo.cutType) {
                isStyle = false;
              } else {
                if (
                  (this.node.extraInfo.cutType === "自定义" ||
                    this.node.extraInfo.cutType === "固定长度") &&
                  !this.node.extraInfo.param
                ) {
                  isStyle = false;
                }
                if (this.node.extraInfo.cutType === "分隔截取") {
                  if (
                    !this.node.extraInfo.separator ||
                    !this.node.extraInfo.targetIndex
                  ) {
                    isStyle = false;
                  }
                }
              }
              break;
            case "contentFilterService":
              isStyle = false;
              if (this.node.extraInfo.regex) {
                isStyle = true;
              }
              break;
            case "shufSelectorService":
              isStyle = true;
              if (
                (!this.node.extraInfo.count &&
                  this.node.extraInfo.type == "constant") ||
                (!this.node.extraInfo.ratio &&
                  this.node.extraInfo.type == "ratio")
              ) {
                isStyle = false;
              }
              break;
            case "randomPhoneService":
              isStyle = true;
              if (
                !this.node.extraInfo.count ||
                !this.node.extraInfo.returnType
              ) {
                isStyle = false;
              }
              break;
            case "prioritySelectorService":
              isStyle = true;
              if (
                !this.node.extraInfo.count ||
                !this.node.extraInfo.poolFileId
              ) {
                isStyle = false;
              }
              break;
            case "multipleFileFilterService":
              isStyle = true;
              if (this.node.extraInfo.fileList.length == 0) {
                isStyle = false;
              }
              break;
            case "quickFilterService":
              isStyle = true;
              if (
                (!this.node.extraInfo.excludeArea &&
                  !this.node.extraInfo.includeArea &&
                  this.node.extraInfo.includeOperator.length == 0 &&
                  !this.node.extraInfo.segments) ||
                !this.node.extraInfo.columnIndex ||
                (this.node.extraInfo.countLimitEnabled &&
                  !this.node.extraInfo.limitCount)
              ) {
                isStyle = false;
              }
              break;
            case "sortService":
              isStyle = true;
              if (
                this.node.extraInfo.columnType == "assign" &&
                (!this.node.extraInfo.index || !this.node.extraInfo.separator)
              ) {
                isStyle = false;
              }
              break;
            case "fileSplitService":
              isStyle = true;
              if (!this.node.extraInfo.splitNum) {
                isStyle = false;
              }
              break;
            case "lengthFilterService":
              isStyle = true;
              if (!this.node.extraInfo.scope) {
                isStyle = false;
              }
              break;
            case "contentFormatService":
              isStyle = true;
              if (!this.node.extraInfo.type) {
                isStyle = false;
              } else if (
                this.node.extraInfo.type == "format" &&
                (!this.node.extraInfo.separator || !this.node.extraInfo.format)
              ) {
                isStyle = false;
              }
              break;
            case "contentAppendService":
              isStyle = true;
              if (!this.node.extraInfo.fileId) {
                isStyle = false;
              }
              break;
            case "shellService":
              isStyle = true;
              if (!this.node.extraInfo.cmd) {
                isStyle = false;
              }
              break;
            case "blacklistFilterService":
              isStyle = true;
              if (!this.node.extraInfo.source) {
                isStyle = false;
              }
              break;
            case "phone2GuishudiService":
              isStyle = true;
              if (
                !this.node.extraInfo.needProvince &&
                !this.node.extraInfo.needCity &&
                !this.node.extraInfo.needOperator
              ) {
                isStyle = false;
              }
              break;
            case "joinService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.matchIndexOne ||
                !this.node.extraInfo.matchIndexTwo ||
                !this.node.extraInfo.outputFormat
              ) {
                isStyle = false;
              }
              break;
            case "fileSelectService":
              isStyle = true;
              if (this.node.extraInfo.active == "1") {
                if (
                  !this.node.extraInfo.fileId ||
                  !this.node.extraInfo.fileName
                ) {
                  isStyle = false;
                }
              }
              if (this.node.extraInfo.active == "2") {
                if (
                  this.node.extraInfo.fileIdList.length == 0 ||
                  !this.node.extraInfo.taskId
                ) {
                  isStyle = false;
                }
              }
              break;
            case "parseJobFileSelectService":
              isStyle = true;
              if (
                !this.node.extraInfo.fileId ||
                !this.node.extraInfo.fileName
              ) {
                isStyle = false;
              }
              break;
            case "columnConversionService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.index ||
                (this.node.extraInfo.type == "JSON_VALUE" &&
                  !this.node.extraInfo.fields) ||
                (this.node.extraInfo.type == "REPLACE" &&
                  !this.node.extraInfo.replaceOld) ||
                (this.node.extraInfo.type == "SPLIT" &&
                  (!this.node.extraInfo.splitSeparator ||
                    !this.node.extraInfo.splitIndex)) ||
                (this.node.extraInfo.type == "SUBSTRING" &&
                  !this.node.extraInfo.splitExpression) ||
                (this.node.extraInfo.type == "COLUMN_SUPPLEMENT" &&
                  (!this.node.extraInfo.length ||
                    !this.node.extraInfo.chars)) ||
                (this.node.extraInfo.type == "DATE_CALCULATE" &&
                  !this.node.extraInfo.offset)
              ) {
                isStyle = false;
              }
              break;
            case "columnAppendService":
              isStyle = true;
              if (
                (!this.node.extraInfo.content &&
                  this.node.extraInfo.type !== "SERIAL_NUMBER_INCR") ||
                (this.node.extraInfo.type == "SERIAL_NUMBER_INCR" &&
                  (!this.node.extraInfo.initValue ||
                    !this.node.extraInfo.step)) ||
                (!this.node.extraInfo.customerCodeIndex &&
                  this.node.extraInfo.type == "TAG")
              ) {
                isStyle = false;
              }
              break;
            case "parseJobPipelineService":
              isStyle = true;
              if (
                !this.node.extraInfo.taskType ||
                !this.node.extraInfo.requestId ||
                !this.node.extraInfo.accessKey
              ) {
                isStyle = false;
              }
              break;
            case "zipMergeService":
              isStyle = true;
              if (
                !this.node.extraInfo.removeHeaderLineCount ||
                (!this.node.extraInfo.separator &&
                  this.node.extraInfo.type == "excel2txt")
              ) {
                isStyle = false;
              }
              break;
            case "columnGroupService":
              isStyle = true;
              if (
                !this.node.extraInfo.delimiter ||
                !this.node.extraInfo.groupIndex
              ) {
                isStyle = false;
              }
              break;
            case "manualInputService":
              isStyle = true;
              if (!this.node.extraInfo.content) {
                isStyle = false;
              }
              break;
            case "httpSenderService":
              isStyle = true;
              if (
                !this.node.extraInfo.url ||
                !this.node.extraInfo.status ||
                !this.node.extraInfo.message ||
                !this.node.extraInfo.requestId
              ) {
                isStyle = false;
              }
              break;
            case "httpReceiverService":
              isStyle = true;
              if (!this.node.extraInfo.requestId) {
                isStyle = false;
              }
              break;
            case "delayService":
              isStyle = true;
              if (
                !this.node.extraInfo.delaySecond &&
                !this.node.extraInfo.executeDateTime
              ) {
                isStyle = false;
              }
              break;
            case "excel2TxtService":
              isStyle = true;
              if (
                !this.node.extraInfo.removeHeaderLineCount ||
                !this.node.extraInfo.separator
              ) {
                isStyle = false;
              }
              break;
            case "multipleColumn2OneService":
              isStyle = true;
              if (this.node.extraInfo.delimiters.length == 0) {
                isStyle = false;
              }
              break;
            case "ftpService":
              isStyle = true;
              if (
                !this.node.extraInfo.host ||
                !this.node.extraInfo.username ||
                !this.node.extraInfo.password ||
                !this.node.extraInfo.type ||
                !this.node.extraInfo.port
              ) {
                isStyle = false;
              }
              break;
            case "sqlBatchQueryService":
              isStyle = true;
              if (
                !this.node.extraInfo.split ||
                !this.node.extraInfo.datasourceId ||
                !this.node.extraInfo.sql
              ) {
                isStyle = false;
              }
              break;
            case "textToExcelService":
              isStyle = true;
              if (
                !this.node.extraInfo.type ||
                !this.node.extraInfo.separator ||
                (this.node.extraInfo.enableTableHeadName &&
                  !this.node.extraInfo.tableHeadNames)
              ) {
                isStyle = false;
              }
              break;
            case "columnRearrangeService":
              isStyle = true;
              if (
                !this.node.extraInfo.order ||
                !this.node.extraInfo.separator
              ) {
                isStyle = false;
              }
              break;
            case "columnDateFilterService":
              isStyle = true;
              if (
                !this.node.extraInfo.start ||
                !this.node.extraInfo.end ||
                !this.node.extraInfo.index ||
                !this.node.extraInfo.separator
              ) {
                isStyle = false;
              }
              break;
            case "uploadBlacklistService":
              isStyle = true;
              if (!this.node.extraInfo.source) {
                isStyle = false;
              }
              break;
            case "fileUploadCollectService":
              isStyle = true;
              if (!this.node.extraInfo.name || !this.node.extraInfo.collectId) {
                isStyle = false;
              }
              break;
            case "fileCollectDistinctService":
              isStyle = true;
              if (!this.node.extraInfo.collectId) {
                isStyle = false;
              }
              break;
            case "shortUrlCreateService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.domain
              ) {
                isStyle = false;
              }
              break;
            case "dataBaseUpdateService":
              isStyle = true;
              if (this.node.extraInfo.updateMode == "GENERATE_BATCH") {
                if (
                  !this.node.extraInfo.datasourceId ||
                  !this.node.extraInfo.sql ||
                  !this.node.extraInfo.separator
                ) {
                  isStyle = false;
                }
              } else {
                if (!this.node.extraInfo.datasourceId) {
                  isStyle = false;
                }
              }
              break;
            case "dataBaseInsertService":
              isStyle = true;
              if (
                !this.node.extraInfo.datasourceId ||
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.tableName ||
                this.node.extraInfo.fieldItemList.length == 0
              ) {
                isStyle = false;
              }
              break;
            case "sipCallTaskCreateService":
              isStyle = true;
              if (
                !this.node.extraInfo.taskName ||
                !this.node.extraInfo.projectToken
              ) {
                isStyle = false;
              }
              break;
            case "dataStatisticsService":
              isStyle = true;
              if (
                !this.node.extraInfo.baseColumn ||
                !this.node.extraInfo.separator ||
                this.node.extraInfo.conditionList.length == 0
              ) {
                isStyle = false;
              }
              break;
            case "apiLogToDzDkDataService":
              isStyle = true;
              if (!this.node.extraInfo.requestId) {
                isStyle = false;
              }
              break;
            case "columnDistinctService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.sortIndex ||
                !this.node.extraInfo.distinctIndex
              ) {
                isStyle = false;
              }
              break;
            case "alarmService":
              isStyle = true;
              if (
                !this.node.extraInfo.content ||
                !this.node.extraInfo.userLoginName
              ) {
                isStyle = false;
              }
              break;
            case "columnSupplementService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                this.node.extraInfo.supplementList.length == 0
              ) {
                isStyle = false;
              }
              break;
            case "ossUploadService":
              isStyle = true;
              if (
                !this.node.extraInfo.filePath ||
                !this.node.extraInfo.bucketName ||
                !this.node.extraInfo.storageType
              ) {
                isStyle = false;
              }
              break;
            case "logicViewSynService":
              isStyle = true;
              if (!this.node.extraInfo.viewCode) {
                isStyle = false;
              }
              break;
            case "addShortUrlGroupService":
              isStyle = true;
              if (
                !this.node.extraInfo.activityCode ||
                !this.node.extraInfo.domain ||
                !this.node.extraInfo.category ||
                !this.node.extraInfo.expireRule ||
                (this.node.extraInfo.expireRule !== "UN_EXPIRE" &&
                  !this.node.extraInfo.expireSeconds)
              ) {
                isStyle = false;
              }
              if (
                this.node.extraInfo.active == "1" &&
                !this.node.extraInfo.longUrl
              ) {
                isStyle = false;
              }
              if (
                this.node.extraInfo.active == "2" &&
                !this.node.extraInfo.longUrlGroupId
              ) {
                isStyle = false;
              }
              break;
            case "dataFilterBaseAction":
              isStyle = true;
              if (!this.node.extraInfo.action) {
                isStyle = false;
              }
              break;
            case "inputFileDataCheckService":
              isStyle = true;
              if (
                (this.node.extraInfo.columnType == "INDEX_COLUMNS" &&
                  !this.node.extraInfo.columnIndex) ||
                !this.node.extraInfo.passRate
              ) {
                isStyle = false;
              }
              break;
            case "uploadXinBaService":
              isStyle = true;
              if (
                !this.node.extraInfo.taskName ||
                !this.node.extraInfo.taskTemplateId
              ) {
                isStyle = false;
              }
              break;
            case "jsonFormatService":
              isStyle = true;
              if (
                !this.node.extraInfo.fields ||
                !this.node.extraInfo.separator
              ) {
                isStyle = false;
              }
              break;
            case "kafkaReceiverService":
              isStyle = true;
              if (!this.node.extraInfo.topic) {
                isStyle = false;
              }
              break;
            case "uploadQuDianService":
              isStyle = true;
              if (
                !this.node.extraInfo.taskName ||
                !this.node.extraInfo.taskTemplateId
              ) {
                isStyle = false;
              }
              break;
            case "limiterService":
              isStyle = true;
              if (!this.node.extraInfo.key) {
                isStyle = false;
              }
              break;
            case "sync2ClickhouseService":
              isStyle = true;
              if (
                !this.node.extraInfo.datasourceId ||
                !this.node.extraInfo.tableName ||
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.columns
              ) {
                isStyle = false;
              }
              break;
            case "concurrentLimitService":
              isStyle = true;
              if (!this.node.extraInfo.key) {
                isStyle = false;
              }
              break;
            case "fileListUploadService":
              isStyle = true;
              if (!this.node.extraInfo.name) {
                isStyle = false;
              }
              break;
            case "fileCompressionService":
              isStyle = true;
              if (
                this.node.extraInfo.fileOrDirList.length == 0 ||
                (this.node.extraInfo.passwordSwitch &&
                  !this.node.extraInfo.password)
              ) {
                isStyle = false;
              }
              break;
            case "rowAppendService":
              isStyle = true;
              if (!this.node.extraInfo.content || !this.node.extraInfo.rowNum) {
                isStyle = false;
              }
              break;
            case "contentSupplementService":
              isStyle = true;
              if (!this.node.extraInfo.total) {
                isStyle = false;
              }
              break;
            case "maxwellFilterService":
              isStyle = true;
              if (!this.node.extraInfo.database || !this.node.extraInfo.types) {
                isStyle = false;
              }
              break;
            case "databaseSelectService":
              isStyle = true;
              if (this.node.extraInfo.mode == "GENERATE_BATCH") {
                if (
                  !this.node.extraInfo.datasourceId ||
                  !this.node.extraInfo.sql ||
                  !this.node.extraInfo.separator
                ) {
                  isStyle = false;
                }
              } else {
                if (!this.node.extraInfo.datasourceId) {
                  isStyle = false;
                }
              }
              break;
            case "databaseSelectColumnSupplementService":
              isStyle = true;
              if (
                !this.node.extraInfo.datasourceId ||
                !this.node.extraInfo.sql
              ) {
                isStyle = false;
              }
              break;
            case "database2MaxwellService":
              isStyle = true;
              if (
                !this.node.extraInfo.datasourceId ||
                !this.node.extraInfo.tableName ||
                !this.node.extraInfo.condition
              ) {
                isStyle = false;
              }
              break;
            case "smsCustomerCodeFetchService":
              isStyle = true;
              if (!this.node.extraInfo.fields) {
                isStyle = false;
              }
              break;
            case "columnRandomOffsetService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.index ||
                !this.node.extraInfo.columnType ||
                !this.node.extraInfo.minOffset ||
                !this.node.extraInfo.maxOffset
              ) {
                isStyle = false;
              }
              break;
            case "maxwell2DatabaseService":
              isStyle = true;
              if (
                !this.node.extraInfo.datasourceId ||
                !this.node.extraInfo.sourceDatabaseName ||
                !this.node.extraInfo.sourceTableName ||
                !this.node.extraInfo.sinkDataSourceId ||
                !this.node.extraInfo.sinkDatabaseName ||
                !this.node.extraInfo.sinkTableName ||
                this.node.extraInfo.fieldMappingList.length == 0
              ) {
                isStyle = false;
              }
              break;
            case "columnFilterService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.expression
              ) {
                isStyle = false;
              }
              break;
            case "repeatFilterService":
              isStyle = true;
              if (!this.node.extraInfo.splitCount) {
                isStyle = false;
              }
              break;
            case "uploadFileSystemService":
              isStyle = true;
              if (!this.node.extraInfo.fileName) {
                isStyle = false;
              }
              break;
            case "tagFilterService":
              isStyle = true;
              if (!this.node.extraInfo.tagContent) {
                isStyle = false;
              }
              break;
            case "ai00166Service":
              isStyle = true;
              if (
                !this.node.extraInfo.ruleDk ||
                !this.node.extraInfo.period ||
                !this.node.extraInfo.filePath
              ) {
                isStyle = false;
              }
              break;
            case "repeatCountAnalysisService":
              isStyle = true;
              if (!this.node.extraInfo.splitCount) {
                isStyle = false;
              }
              break;
            case "uploadMonitorService":
              isStyle = true;
              if (!this.node.extraInfo.code) {
                isStyle = false;
              }
              break;
            case "distributionStatisticsService":
              isStyle = true;
              if (
                !this.node.extraInfo.start ||
                !this.node.extraInfo.end ||
                !this.node.extraInfo.step
              ) {
                isStyle = false;
              }
              break;
            case "columnGroupAndFetchService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                !this.node.extraInfo.groupIndex ||
                !this.node.extraInfo.count
              ) {
                isStyle = false;
              }
              break;
            case "columnMatchAndFetchService":
              isStyle = true;
              if (
                !this.node.extraInfo.mainFileColumns ||
                !this.node.extraInfo.matchFileColumns
              ) {
                isStyle = false;
              }
              break;
            case "fileStoreService":
              isStyle = true;
              if (!this.node.extraInfo.tag || !this.node.extraInfo.code) {
                isStyle = false;
              }
              break;
            case "createFormTaskService":
              isStyle = true;
              if (!this.node.extraInfo.formId) {
                isStyle = false;
              }
              break;
            case "pauseNotifyService":
              isStyle = true;
              if (!this.node.extraInfo.notifyUsers) {
                isStyle = false;
              }
              break;
            case "columnJoinFilterService":
              isStyle = true;
              if (
                !this.node.extraInfo.matchIndexOne ||
                !this.node.extraInfo.matchIndexTwo
              ) {
                isStyle = false;
              }
              break;
            case "variableExtractService":
              isStyle = true;
              if (!this.node.extraInfo.content) {
                isStyle = false;
              }
              break;
            case "variableGenerateService":
              isStyle = true;
              if (
                !this.node.extraInfo.separator ||
                this.node.extraInfo.variableList.length == 0
              ) {
                isStyle = false;
              }
              break;
            case "phoneGenerateService":
              isStyle = true;
              if (
                !this.node.extraInfo.segments ||
                !this.node.extraInfo.returnType
              ) {
                isStyle = false;
              }
              break;
            case "upload2OmegaMidTableService":
              isStyle = true;
              if (!this.node.extraInfo.midTableName) {
                isStyle = false;
              }
              break;
          }
        }
        return {
          "ef-node-left": isStyle,
          "ef-node-left-warnning": !isStyle,
        };
      }
    },
    nodeContainerClass() {
      return {
        "ef-node-container": true,
        "node-endpoint-active": this.endpointShow,
        processNode:
          this.node.type == "PROCESS_INPUT" ||
          this.node.type == "PROCESS_OUTPUT",
      };
    },
    // 节点容器样式
    nodeContainerStyle() {
      return {
        top: this.node.top,
        left: this.node.left,
        borderColor:
          this.runningNodeId && this.runningNodeId == this.node.id
            ? this.node.type == "COMPOSE_NODE"
              ? "transparent !important"
              : "red !important"
            : this.getColor,
        zIndex:
          (this.runningNodeId && this.runningNodeId == this.node.id) ||
          (this.errorNodeId && this.errorNodeId == this.node.id)
            ? "999"
            : "3",
      };
    },
    getColor() {
      let arr = this.composeWorkflowTaskList.filter((item) => {
        return item.name == this.node.name && this.node.type == "COMPOSE_NODE";
      });
      let color = "";
      if (arr.length > 0) {
        color = arr[0].color + " !important";
      }
      return color;
    },
    nodeIcoClass() {
      var nodeIcoClass = {};
      nodeIcoClass["nodeIcon"] = true;
      return nodeIcoClass;
    },
    isStartNode() {
      // 结束节点不显示
      if (this.node.maxOutputCount === 0) {
        return false;
      }
      return true;
    },
    processIf() {
      return (
        this.node.type == "diyAbstractMultipleInputAndOutputService" ||
        this.node.type == "diySingleInputAndMultipleOutputService" ||
        this.node.type == "diyAbstractSingleInputAndOutputService" ||
        this.node.type == "diyAbstractSingleInputAndNoOutputService" ||
        this.node.type == "diyAbstractMultipleInputAndOneOutputService"
      );
    },
    getMenuName() {
      for (let i = 0; i < this.menuList.length; i++) {
        let children = this.menuList[i].children;
        for (let j = 0; j < children.length; j++) {
          if (children[j].type === this.node.type) {
            return this.menuList[i].name;
          }
        }
      }
    },
  },
  watch: {
    endpointShow: {
      handler(newVal) {
        if (newVal) {
          document.body.addEventListener("mouseup", this.dragLine);
        } else {
          document.body.removeEventListener("mouseup", this.dragLine);
        }
      },
    },
  },
  mounted() {
    if (this.node.type == "streamSpeedMonitorService") {
      this.loadDynamicApiData("speed");
      this.speedTimer = setInterval(() => {
        this.loadDynamicApiData("speed");
      }, 2000);
    }
    if (this.node.type == "streamContentMonitorService") {
      this.loadDynamicApiData("contentSize");
      this.contentTimer = setInterval(() => {
        this.loadDynamicApiData("contentSize");
      }, 2000);
    }
  },
  methods: {
    jumpPipelineList() {
      let url = this.$router.resolve(this.node.extraInfo.taskUrl);
      window.open(url.href);
    },
    loadDynamicApiData(api) {
      let data = {
        action: this.node.type,
        api,
        id: this.taskId + "#" + this.node.id,
      };
      this.$http.get("/sysPipeline/loadDynamicApiData", data).then((res) => {
        if (res.result == 200) {
          if (api == "speed") {
            this.speed = res.data;
            this.$set(
              this.speed,
              "RECENT_MINUTE_1Thousand",
              this.formatSpeed(this.speed.RECENT_MINUTE_1)
            );
            this.$set(
              this.speed,
              "RECENT_MINUTE_5Thousand",
              this.formatSpeed(this.speed.RECENT_MINUTE_5)
            );
            this.$set(
              this.speed,
              "RECENT_MINUTE_10Thousand",
              this.formatSpeed(this.speed.RECENT_MINUTE_10)
            );
          } else if (api == "contentSize") {
            this.contentSize = res.data;
          } else if (api == "content") {
            this.contentList = res.data;
            this.contentShow = true;
          }
        }
      });
    },
    formatSpeed(num) {
      if (num < 1000) {
        return num;
      } else if (num < 100000) {
        let newNum = parseFloat((num / 1000).toFixed(1));
        return newNum + "K";
      } else {
        let newNum = parseFloat((num / 10000).toFixed(1));
        return newNum + "W";
      }
    },
    dragLine() {
      this.endpointShow = false;
    },
    download() {
      this.$emit("download", this.node);
    },
    confirmPauseNode(passed) {
      this.$emit("confirmPauseNode", this.node.id, passed);
    },
    endpointNodeMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      // let outputCount = 0;

      // this.data.lineList.forEach((item) => {
      //   if (item.from == this.node.id) {
      //     outputCount += 1;
      //   }
      // });
      // if (outputCount >= this.node.maxOutputCount) {
      //   return;
      // }
      this.$emit("endpointNodeMenu", e, this.node);
    },
    generateParameter() {
      this.loadingTable = true;
      let data = {
        pipelineTaskId: this.taskId,
        nodeId: this.node.id,
      };
      this.$http.get("/generateParameter/listParameter", data).then((res) => {
        if (res.result === 200) {
          this.listParameter = res.data;
          this.listParameterShow = true;
          this.loadingTable = false;
        }
      });
    },
    addParameter() {
      this.updateParameterTitle = "新增";
      this.parameterName = "${xx}";
      this.parameterValue = "${source_file_name}";
      this.parameterCustomValue = "";
      this.parameterRemark = "";
      this.parameterTag = "";
      this.parameterInputSwitch = false;
      this.updateParameterShow = true;
    },
    editParameter(val) {
      this.updateParameterTitle = "修改";
      this.parameterId = val.id;
      this.parameterName = val.name;
      let parameterValueArr = this.parameterValueSelectList.filter((item) => {
        return item.value == val.value;
      });
      if (parameterValueArr.length > 0) {
        this.parameterValue = val.value;
      } else {
        this.parameterValue = "";
        this.parameterCustomValue = val.value;
      }
      this.parameterRemark = val.remark;
      this.parameterTag = val.tag;
      this.parameterInputSwitch = val.input;
      this.updateParameterShow = true;
    },
    updateParameterSubmit() {
      let data = {
        pipelineTaskId: this.taskId,
        nodeId: this.node.id,
        name: this.parameterName,
        value:
          this.parameterValue == ""
            ? this.parameterCustomValue
            : this.parameterValue,
        remark: this.parameterRemark,
        tag: this.parameterTag,
        input: this.parameterInputSwitch,
      };

      if (this.updateParameterTitle == "新增") {
        this.$http
          .json_post("/generateParameter/addParameter", data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("新增成功");
              this.updateParameterShow = false;
              this.generateParameter();
            }
          });
      } else {
        data.id = this.parameterId;
        this.$http
          .json_post("/generateParameter/editParameter", data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("修改成功");
              this.updateParameterShow = false;
              this.generateParameter();
            }
          });
      }
    },
    deleteParameter(val) {
      let data = {
        id: val.id,
      };
      this.$http
        .json_post("/generateParameter/deleteParameter", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("删除成功");
            this.generateParameter();
          }
        });
    },
    updateNode() {
      let type = this.node.type.split("Service")[0];
      this.$emit("setClick", this.node.id, type);
    },
    deleteNode() {
      this.$emit("setClick", this.node.id, "delete_click");
    },
    // 点击节点
    clickNode() {
      this.$emit("clickNode", this.node.id, this.node.name, this.node.type);
    },
    dblclickNode() {
      if (this.node.type == "COMPOSE_NODE") {
        this.$emit("dblclickNode", this.node.name);
      }
    },
    // 鼠标移动后抬起
    changeNodeSite() {
      // 避免抖动
      if (
        this.node.left == this.$refs.node.style.left &&
        this.node.top == this.$refs.node.style.top
      ) {
        return;
      }
      this.$emit("changeNodeSite", {
        nodeId: this.node.id,
        left: this.$refs.node.style.left,
        top: this.$refs.node.style.top,
      });
    },
    // 鼠标右击
    nodeRightMenu(e) {
      e.preventDefault();
      e.stopPropagation();
      let testable = false;
      if (
        this.node.type !== "COMPOSE_NODE" &&
        this.node.type !== "PROCESS_INPUT" &&
        this.node.type !== "PROCESS_OUTPUT"
      ) {
        testable = this.node.testable;
      }
      this.$emit("nodeRightMenu", e, "node", { node: this.node, testable });
    },
  },
  beforeDestroy() {
    if (this.speedTimer) {
      clearInterval(this.speedTimer);
      this.speedTimer = null;
    }
    if (this.contentTimer) {
      clearInterval(this.contentTimer);
      this.contentTimer = null;
    }
  },
};
</script>
<style scoped>
.greenSwitch {
  background-color: #00d300 !important;
}
:deep() .ant-switch-checked {
  background-color: #1890ff !important;
}
</style>
<style lang="scss" scoped>
/*节点的最外层容器*/
.ef-node-container {
  position: absolute;
  display: flex;
  width: 223px;
  min-height: 32px;
  border: 3px solid #f2f2f2;
  border-radius: 3px;
  background-color: #fff;
  box-sizing: content-box;
  z-index: 3;
  &:hover {
    cursor: move;
    border: 3px solid #7d44b3;
    .endpoint {
      background-color: #7d44b3;
    }
  }

  /*节点左侧的竖线*/
  .ef-node-left {
    width: 4px;
    background-color: #1879ff;
  }

  .ef-node-left-warnning {
    width: 4px;
    background-color: #e6a23c;
  }
  .ef-node-left-error {
    width: 4px;
    background-color: #e63c3c !important;
  }

  .ef-node-content {
    width: 98%;
    display: flex;
    flex-direction: column;
    position: relative;

    .ef-node-content-item {
      display: flex;

      /*节点显示的文字*/
      .ef-node-text {
        color: #565758;
        font-size: 12px;
        line-height: 32px;
        margin-left: 4px;
        width: 100px;
        /* 设置超出宽度文本显示方式*/
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      /*节点左侧的图标*/
      .ef-node-left-ico {
        line-height: 32px;
        margin-left: 4px;
      }

      .ef-node-content-head {
        height: 20px;
        padding: 0 5px;
        display: flex;
        justify-content: right;
        margin-left: auto;

        .ef-node-setting-ico {
          line-height: 20px;
          margin-left: 4px;
          &:hover {
            cursor: pointer;
          }
        }
      }

      /*节点右侧的图标*/
      .ef-node-right-ico {
        line-height: 20px;
        margin-right: 5px;
        cursor: default;
        float: right;
      }

      .ef-node-action-ico {
        line-height: 20px !important;
        height: 20px !important;
        float: right;
        padding: 0 !important;
      }

      /*节点的几种状态样式*/
      .ef-node-state-success,
      .ef-node-state-running {
        color: #84cf65;
      }
      .ef-node-state-error {
        color: #f56c6c;
      }

      .ef-node-state-warning {
        color: #e6a23c;
      }
    }

    .ef-node-content-extraInfo {
      padding-bottom: 8px;

      .ef-node-content-info {
        width: 200px;
        color: #565758;
        font-size: 12px;
        margin-left: 8px;
        line-height: 22px;
        /* 设置超出宽度文本显示方式*/
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        .ant-progress-line {
          width: 95% !important;
          font-size: 12px !important;
        }
      }
    }

    .endpoint {
      position: absolute;
      right: -17px;
      top: calc(50% - 10px);
      width: 15px;
      height: 20px;
      cursor: pointer;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .flow-node-drag {
      cursor: crosshair;
    }
  }
}

.node-endpoint-active {
  /* 设置移动样式*/
  cursor: move;
  border: 3px solid #7d44b3;
}

.endpoint-active {
  background-color: #7d44b3;
}

.processNode {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  text-align: center;

  .ef-node-content-item {
    width: 100%;
    height: 100%;
    line-height: 60px;
  }

  .ef-node-text {
    line-height: 60px !important;
  }
}
.contentList {
  .contentItem {
    display: flex;
    align-items: center;
    .content {
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .button {
      margin-left: auto;
    }
  }
}
</style>
