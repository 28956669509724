<template>
  <!-- 节点的右键菜单 -->
  <div
    class="right_menu"
    id="right_menu"
    v-if="menuData.menuType == 'node' && !processCompose"
  >
    <ul>
      <template
        v-if="
          this.menuData.data.node.type == 'COMPOSE_NODE' &&
          !viewOnly &&
          composeNodeId == ''
        "
      >
        <li @click="composeNodeReName()">
          <div>
            <a-icon type="edit" />
            重命名
          </div>
        </li>
        <li
          style="border-top: 1px solid #e9edf2; padding-top: 4px"
          @click="deComposeClick()"
        >
          <div>
            <a-icon type="down" />
            展开
          </div>
        </li>
      </template>

      <template v-if="this.menuData.data.node.type !== 'COMPOSE_NODE'">
        <li @click="dataClick()" v-if="menuData.data.node.extraInfoShow">
          <div>
            <a-icon type="edit" />
            {{ viewOnly ? "查看" : "参数设置" }}
          </div>
        </li>

        <template v-if="!viewOnly">
          <li @click="deleteClick" style="color: #ff4d4f">
            <div>
              <a-icon type="delete" />
              删除
            </div>
          </li>
        </template>

        <li @click="copyClick('node', $event)">
          <div>
            <a-icon type="copy" />
            复制
          </div>
        </li>
        <li
          @click="resetStateClick"
          v-if="resetStateShow && status !== 'RUNNING'"
        >
          <div>
            <a-icon type="reload" />
            重置状态
          </div>
        </li>
        <div style="border-top: 1px solid #e9edf2; padding-top: 4px"></div>
        <li @click="testClick" v-if="menuData.data.testable && !viewOnly">
          <div>
            <a-icon type="share-alt" />
            测试
          </div>
        </li>

        <li @click="processClick()" v-if="processClickSwitch">
          <div>
            <a-icon type="arrow-right" />
            查看自定义节点
          </div>
        </li>
        <li v-copy="nodeUrl">
          <div>
            <a-icon type="copy" />
            复制节点地址
          </div>
        </li>
        <li
          @click="notifyChange"
          v-if="
            status == 'RUNNING' &&
            menuData.data.node.state !== 'SUCCESS' &&
            menuData.data.node.state !== 'ERROR'
          "
        >
          <div>
            <a-icon type="notification" />
            {{ this.menuData.notifyState ? "取消完成通知" : "完成后通知我" }}
          </div>
        </li>
      </template>

      <li @click="selectSubsequentNodes" v-if="selectSubsequentNodesSwitch">
        <div>
          <a-icon type="table" />
          选中后续节点
        </div>
      </li>
      <li @click="updateRemark">
        <div>
          <a-icon type="edit" />
          修改备注
        </div>
      </li>
    </ul>
  </div>
  
  <!-- 线的右键菜单 -->
  <div
    class="right_menu"
    id="right_menu"
    v-else-if="menuData.menuType == 'line' && !viewOnly"
  >
    <ul>
      <li v-if="menuData.lineParameterShow" @click="lineClick">
        <div>
          <a-icon type="edit" />
          {{ viewOnly ? "查看" : "参数设置" }}
        </div>
      </li>
      <template v-if="!viewOnly">
        <li @click.stop="addIntermediateNodeClick">
          <div>
            <a-icon type="plus" />
            添加中间节点
          </div>
        </li>
        <li @click="deleteLineClick" style="color: #ff4d4f">
          <div>
            <a-icon type="delete" />
            删除
          </div>
        </li>
      </template>
    </ul>
  </div>

  <!-- 画布的右键菜单 -->
  <div
    class="right_menu"
    id="right_menu"
    v-else-if="menuData.menuType == 'panel'"
  >
    <ul>
      <li
        v-if="menuData.data.pasteShow && !viewOnly"
        @click="pasteClick($event)"
      >
        <div>
          <a-icon type="copy" />
          粘贴
        </div>
      </li>
      <li @click="checkAllClick($event)">
        <div>
          <a-icon type="table" />
          一键全选
        </div>
      </li>
    </ul>
  </div>

  <!-- 框选的右键菜单 -->
  <div
    class="right_menu"
    id="right_menu"
    v-else-if="menuData.menuType == 'frameSelection'"
  >
    <ul>
      <li
        v-if="!viewOnly && frameSelectionNodeListHasComposeNodeAndDelete"
        @click="deleteFrameSelectionClick"
        style="color: #ff4d4f"
      >
        <div>
          <a-icon type="delete" />
          删除
        </div>
      </li>
      <li @click="copyClick('FrameSelection')">
        <div>
          <a-icon type="copy" />
          复制
        </div>
      </li>
      <li @click="resetStateAllClick">
        <div>
          <a-icon type="reload" />
          重置状态
        </div>
      </li>
      <li
        v-if="
          !viewOnly &&
          composeNodeId == '' &&
          frameSelectionNodeListHasComposeNodeAndCompose
        "
        @click="composeClick()"
      >
        <div>
          <a-icon type="up" />
          收缩
        </div>
      </li>

      <li @click="createTaskClick">
        <div>
          <a-icon type="plus" />
          创建任务
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "right_menu",
  props: [
    "menuData",
    "viewOnly",
    "data",
    "taskId",
    "composeNodeId",
    "frameSelectionNodeListHasComposeNodeAndCompose",
    "frameSelectionNodeListHasComposeNodeAndDelete",
    "status",
  ],
  data() {
    return {
      resetStateShow: false,
      nodeId: "",
    };
  },
  computed: {
    // 自定义节点
    processClickSwitch() {
      return (
        this.menuData.data.node.type ==
          "diyAbstractMultipleInputAndOutputService" ||
        this.menuData.data.node.type ==
          "diySingleInputAndMultipleOutputService" ||
        this.menuData.data.node.type ==
          "diyAbstractSingleInputAndOutputService" ||
        this.menuData.data.node.type ==
          "diyAbstractSingleInputAndNoOutputService" ||
        this.menuData.data.node.type ==
          "diyAbstractMultipleInputAndOneOutputService"
      );
    },
    // 输入输出节点
    processCompose() {
      return (
        (this.menuData.data.node.type == "PROCESS_INPUT" ||
          this.menuData.data.node.type == "PROCESS_OUTPUT") &&
        this.composeNodeId !== ""
      );
    },
    // 后续是否有节点
    selectSubsequentNodesSwitch() {
      let arr = this.data.lineList.filter((line) => {
        return line.from == this.nodeId;
      });
      if (arr.length > 0) {
        return true;
      }
      return false;
    },
    nodeUrl() {
      const currentQuery = { ...this.$route.query };

      currentQuery.nodeId = this.nodeId;

      const newPath = {
        path: this.$route.path,
        query: currentQuery,
      };

      const resolvedRoute = this.$router.resolve(newPath);
      return location.origin + resolvedRoute.href;
    },
  },
  mounted() {
    let div1 = document.getElementById("right_menu");
    if (div1) {
      div1.style.left = this.menuData.left;
      div1.style.top = this.menuData.top;
    }
    if (this.menuData.menuType == "node") {
      this.nodeId = this.menuData.data.node.id;
      let arr = this.data.nodeList.filter((node) => {
        return node.id == this.nodeId;
      });
      if (arr.length > 0) {
        this.resetStateShow = arr[0].state !== "WAITING";
      } else {
        this.resetStateShow = false;
      }
    }
  },
  methods: {
    deleteFrameSelectionClick() {
      this.$emit("FrameSelectionClick", "删除");
    },
    copyClick(type) {
      if (type == "node") {
        this.$emit("FrameSelectionClick", "复制", null, type, this.nodeId);
      } else {
        this.$emit("FrameSelectionClick", "复制", null, type);
      }
    },
    composeClick() {
      this.$emit("FrameSelectionClick", "收缩");
    },
    deComposeClick() {
      this.$emit("FrameSelectionClick", "展开", null, null, this.nodeId);
    },
    composeNodeReName() {
      this.$emit("FrameSelectionClick", "重命名", null, null, this.nodeId);
    },
    createTaskClick() {
      this.$emit("FrameSelectionClick", "创建任务");
    },
    pasteClick(e) {
      this.$emit("FrameSelectionClick", "粘贴", e);
    },
    checkAllClick() {
      this.$emit("checkAllClick");
    },
    // 点击测试
    testClick() {
      let taskType = this.menuData.data.node.type.split("Service")[0];
      this.$emit("setClick", this.nodeId, taskType, null, true);
    },
    addIntermediateNodeClick(e) {
      this.$emit("addIntermediateNode", e, this.menuData.data.from);
    },
    deleteLineClick() {
      this.$emit("deleteElement");
    },
    // 点击删除
    deleteClick() {
      this.$emit("setClick", this.nodeId, "delete_click");
    },
    lineClick() {
      this.$emit("setClick", null, null, this.menuData.data);
    },
    resetStateAllClick() {
      this.$emit("FrameSelectionClick", "重置状态");
    },
    resetStateClick() {
      this.$emit("setClick", this.nodeId, "resetState_click");
    },
    selectSubsequentNodes() {
      this.$emit("setClick", this.nodeId, "selectSubsequentNodes");
    },
    updateRemark() {
      this.$emit("setClick", this.nodeId, "updateRemark");
    },
    notifyChange() {
      this.$emit("setClick", this.nodeId, "notifyChange");
    },
    // 参数设置
    dataClick() {
      let taskType = this.menuData.data.node.type.split("Service")[0];
      this.$emit("setClick", this.nodeId, taskType);
    },
    processClick() {
      let arr = this.data.nodeList.filter((node) => {
        return node.id == this.nodeId;
      });
      if (arr.length > 0) {
        this.$router.push({
          path: "/panel",
          query: {
            id: this.taskId,
            processNodeId: arr[0].nodeId,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/* 右击框 */
.right_menu {
  width: 180px;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border: 1px solid #e9edf2;
  z-index: 1000;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 2px;
  font-size: 13px;

  ul {
    width: 100%;
    padding: 0;
    margin: 0;

    li {
      padding: 0 4px;
      position: relative;
      margin-bottom: 2px;
      div {
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        cursor: pointer;
        padding-left: 36px;
        padding-right: 8px;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        i {
          position: absolute;
          left: 8px;
          font-size: 18px;
        }
        &:hover {
          background-color: #f3f5f9;
        }
      }
    }
  }
}
</style>
