<template>
  <div class="tabTaskList">
    <a-tabs v-model="activeName">
      <a-tab-pane tab="普通任务" key="1">
        <router-view
          name="second"
          v-if="activeName == '1'"
          :loginName="loginName"
          :userName="userName"
        ></router-view>
      </a-tab-pane>
      <a-tab-pane tab="定时任务" key="2">
        <router-view name="second" v-if="activeName == '2'"></router-view>
      </a-tab-pane>
      <a-tab-pane tab="接口任务" key="3">
        <router-view name="second" v-if="activeName == '3'"></router-view>
      </a-tab-pane>
    </a-tabs>
    <div class="lockStatus">
      <a-tooltip placement="leftBottom" overlayClassName="lockStatusTip">
        <template slot="title" v-if="lockStatus !== '已锁定'">
          撞库任务数量:{{ parseJobCount }}<br />
          pipeline任务数量:{{ pipelineCount }}
        </template>
        <a-tag :color="statusColor">{{ lockStatus }}</a-tag>
      </a-tooltip>
      <a-button type="link" @click="modifyPipelineLockStatus">
        {{ lockStatus == "未锁定" ? "锁定" : "解除锁定" }}
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabTaskList",
  props: ["loginName", "userName"],
  data() {
    return {
      activeName: "1",
      lockStatus: "未锁定",
      statusColor: "green",
      parseJobCount: "",
      pipelineCount: "",
    };
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal) {
        if (newVal == "/tabTaskList/dataRecord") {
          this.activeName = "1";
        } else if (newVal == "/tabTaskList/timedTask") {
          this.activeName = "2";
        } else if (newVal.indexOf("/tabTaskList/apiList") !== -1) {
          this.activeName = "3";
        }
      },
    },
    activeName: {
      handler(newVal) {
        if (newVal === "1") {
          this.$router.replace("/tabTaskList/dataRecord");
        } else if (newVal === "2") {
          this.$router.replace("/tabTaskList/timedTask");
        } else {
          this.$router.replace("/tabTaskList/apiList");
        }
      },
    },
  },
  created() {
    this.getPipelineLockStatus();
  },
  methods: {
    getPipelineLockStatus() {
      this.$http.get("/pipelineTask/pipelineLockStatus").then((res) => {
        if (res.result == 200) {
          switch (res.data.status) {
            case "UNLOCK" || null:
              this.statusColor = "green";
              this.lockStatus = "未锁定";
              break;
            case "LOCKING":
              this.statusColor = "orange";
              this.lockStatus = "锁定中";
              break;
            case "LOCKED":
              this.statusColor = "red";
              this.lockStatus = "已锁定";
              break;
          }
          this.parseJobCount = res.data.parseJobCount;
          this.pipelineCount = res.data.pipelineCount;
        }
      });
    },
    modifyPipelineLockStatus() {
      this.$http.post("/pipelineTask/modifyPipelineLockStatus").then((res) => {
        if (res.result == 200) {
          this.$message.success("修改成功");
          this.getPipelineLockStatus();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.tabTaskList {
  position: relative;

  .lockStatus {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
<style lang="scss">
.lockStatusTip {
  .ant-tooltip-inner {
    width: 160px !important;
  }
}
</style>
